<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div v-if="$store.state.cashier != ''">
      <div v-if="day_block > 0">
        <div
          v-if="
            partnerBalance.tempo_package == 0 ||
            partnerBalance.tempo_package >= 2 * partnerBalance.permanent_package
          "
          class="text-center mt-16 white--text"
        >
          <h2>Insufficient Balance, Please contact the Admin of this system</h2>
        </div>

        <div v-else>
          <div class="hidden-md-and-down">
            <div v-if="whichToShow == 'dog'">
              <v-app-bar elevation="0" app id="navBar" dark>
                <v-layout row wrap class="" style="font-size: 14px">
                  <v-flex xs2 class="" v-if="currentGame.length > 0">
                    <p>GAME# {{ currentGame[0].game_number }}</p>
                  </v-flex>
                  <v-flex xs2 class="mt-2 mr-2 pr-1 pl-1">
                    <p>
                      <v-progress-linear
                        v-model="timePercent"
                        height="8"
                      ></v-progress-linear>
                    </p>
                  </v-flex>
                  <v-flex
                    class="red--text"
                    xs2
                    v-if="minutes == '00' && parseInt(seconds) <= 20"
                  >
                    <p>GAME CLOSES IN {{ minutes }}:{{ seconds }}</p>
                  </v-flex>

                  <v-flex class="" xs2 v-else>
                    <p>GAME CLOSES IN {{ minutes }}:{{ seconds }}</p>
                  </v-flex>
                  <v-flex xs1 class="pl-2">
                    <v-btn
                      outlined
                      rounded
                      x-small
                      @click="verifyDialog = true"
                    >
                      <span class="text-capitalize">Verify</span>
                    </v-btn>
                  </v-flex>
                  <v-flex xs1 class="pl-2">
                    <v-btn
                      outlined
                      rounded
                      x-small
                      @click="cancelDialog = true"
                    >
                      <span class="text-capitalize">Cancel</span>
                    </v-btn>
                  </v-flex>

                  <v-flex xs1 class="pl-2">
                    <v-btn text x-small @click="tmhPopUp = true">
                      <span class="text-capitalize">TMH </span>
                    </v-btn>
                  </v-flex>
                </v-layout>

                <v-btn small text>
                  <span class="mr-2 text-capitalize">{{ whichToShow }} </span>
                </v-btn>

                <v-btn small text @click="reportTicketDialog = true">
                  <span class="mr-2 text-capitalize">Report ticket</span>
                </v-btn>
                <v-btn small text @click="logoutClicked">
                  <span class="mr-2 text-capitalize">Logout</span>
                </v-btn>
                <a @click="helpDialog = true">Help</a>
              </v-app-bar>

              <v-layout row wrap class="ml-1 mt-0">
                <v-flex xs6 class="" v-if="currentGame.length > 0">
                  <v-layout
                    row
                    wrap
                    class="text-center mr-3"
                    justify-space-around
                  >
                    <v-flex
                      xs12
                      v-if="partnerBalance.tempo_package <= 1000"
                      class="pa-1 red--text"
                    >
                      <span style="font-size: 14px"
                        >Before the system shuts down, please let your manager
                        know that your package is low.
                      </span>
                    </v-flex>

                    <v-layout
                      row
                      wrap
                      v-for="(list, i) in currentGame[0].howmany_dogs"
                      :key="i"
                      justify-space-around
                      class="white--text"
                    >
                      <v-flex xs6 v-if="i == 0"> </v-flex>
                      <v-flex xs2 v-if="i == 0">
                        <span class="text-capitalize">win</span>
                      </v-flex>

                      <v-flex xs2 v-if="i == 0">
                        <span class="text-capitalize">place</span>
                      </v-flex>

                      <v-flex xs2 v-if="i == 0">
                        <span class="ttext-capitalize">Combos</span>
                      </v-flex>
                      <v-flex v-if="i == 0" xs12 class="mb-2"> </v-flex>

                      <v-flex xs6>
                        <v-img
                          height="40"
                          width="120"
                          contain
                          :src="require(`../assets/dogsimg/${dogs[i].image}`)"
                        >
                        </v-img>
                      </v-flex>
                      <v-flex xs2>
                        <v-btn
                          @click="
                            selectNumber(
                              'Win*' +
                                (i + 1) +
                                '*' +
                                parseFloat(
                                  currentGame[0].winplace_odd_multiplier
                                    .split(',')
                                    [i].split(':')[0]
                                ).toFixed(2)
                            )
                          "
                          id="dogbtn"
                          text
                          outlined
                          style="background-color: rgba(41, 57, 73, 255)"
                        >
                          <span class="white--text">{{
                            parseFloat(
                              currentGame[0].winplace_odd_multiplier
                                .split(",")
                                [i].split(":")[0]
                            ).toFixed(2)
                          }}</span>
                        </v-btn>
                      </v-flex>

                      <v-flex xs2>
                        <v-btn
                          @click="
                            selectNumber(
                              'Place*' +
                                (i + 1) +
                                '*' +
                                parseFloat(
                                  currentGame[0].winplace_odd_multiplier
                                    .split(',')
                                    [i].split(':')[1]
                                ).toFixed(2)
                            )
                          "
                          text
                          outlined
                          style="background-color: rgba(41, 57, 73, 255)"
                        >
                          <span class="white--text">{{
                            parseFloat(
                              currentGame[0].winplace_odd_multiplier
                                .split(",")
                                [i].split(":")[1]
                            ).toFixed(2)
                          }}</span>
                        </v-btn>
                      </v-flex>

                      <v-flex xs2>
                        <v-btn
                          @click="getCombo(dogs[i])"
                          text
                          outlined
                          style="background-color: rgba(41, 57, 73, 255)"
                        >
                          <span class="white--text text-capitalize">{{
                            "Dog #" + (i + 1)
                          }}</span>
                        </v-btn>
                      </v-flex>
                      <v-flex xs12 class="mt-2 mb-2 white--text">
                        <v-divider :light="true"></v-divider>
                        <!-- <hr /> -->
                      </v-flex>
                    </v-layout>

                    <v-flex xs8 class="mt-2">
                      <v-layout row wrap class="">
                        <v-flex xs2 class="ma-1 text-center" id="numbers">
                          <div class="pt-2 pb-2" id="selectedNum">
                            <a
                              @click="
                                selectNumber(
                                  'Even*Even*' +
                                    parseFloat(
                                      currentGame[0].even_odd_multi.split(
                                        ','
                                      )[2]
                                    ).toFixed(2)
                                )
                              "
                            >
                              <div>
                                <span class="pa-1 white--text">Even</span>
                              </div>
                            </a>
                          </div>
                        </v-flex>
                        <v-flex xs2 class="ma-1 text-center" id="numbers">
                          <div class="pt-2 pb-2" id="selectedNum">
                            <a
                              @click="
                                selectNumber(
                                  'Odd*Odd*' +
                                    parseFloat(
                                      currentGame[0].even_odd_multi.split(
                                        ','
                                      )[3]
                                    ).toFixed(2)
                                )
                              "
                            >
                              <div>
                                <span class="pa-1 white--text">Odd</span>
                              </div>
                            </a>
                          </div>
                        </v-flex>
                        <v-flex xs2 class="ma-1 text-center" id="numbers">
                          <div class="pt-2 pb-2" id="selectedNum">
                            <a
                              @click="
                                selectNumber(
                                  'High*High*' +
                                    parseFloat(
                                      currentGame[0].even_odd_multi.split(
                                        ','
                                      )[0]
                                    ).toFixed(2)
                                )
                              "
                            >
                              <div>
                                <span class="pa-1 white--text">High</span>
                              </div>
                            </a>
                          </div>
                        </v-flex>
                        <v-flex xs2 class="ma-1 text-center" id="numbers">
                          <div class="pt-2 pb-2" id="selectedNum">
                            <a
                              @click="
                                selectNumber(
                                  'Low*Low*' +
                                    parseFloat(
                                      currentGame[0].even_odd_multi.split(
                                        ','
                                      )[1]
                                    ).toFixed(2)
                                )
                              "
                            >
                              <div>
                                <span class="pa-1 white--text">Low</span>
                              </div>
                            </a>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>

                <v-flex xs3>
                  <v-layout
                    row
                    wrap
                    class="mr-1"
                    v-if="
                      selectedNumbers.length > 0 ||
                      selectedEvenOddAndHighLow.length > 0
                    "
                  >
                    <!-- <v-flex xs12>
              <p style="font-size: 20px" class="white--text">
                Selected Numbers
              </p>
            </v-flex> -->
                    <v-flex
                      xs12
                      class="grey mb-1 ml-0 pt-2 pb-2"
                      id="selectedNumbers"
                    >
                      <v-layout row wrap justify-space-around>
                        <v-flex xs6 class="pl-8 pt-1">
                          <a
                            class="pa-1 pt-2 pb-3 white--text"
                            dark
                            @click="
                              selectedNumbers = [];
                              numberInputMoney = [];
                            "
                          >
                            <span class="text-capitalize">Clear</span></a
                          >
                        </v-flex>

                        <v-flex xs6 class="text-center pl-3">
                          <a class="pa-1 pt-2 pb-3" @click="recietOverview('')">
                            <v-icon large class="white--text">print</v-icon>
                          </a>
                        </v-flex>
                      </v-layout>

                      <!-- <v-btn
                id="printBtn"
                text
                dark
                class="pl-10 pr-10 red--text"
                v-if="selectedNumbers.length > 0"
                @click="selectedNumbers = []"
              >
                <span class="text-capitalize">Cancel</span>
              </v-btn> -->
                    </v-flex>
                    <v-flex
                      xs12
                      class="grey mb-1 ml-0 pt-1 pb-1"
                      id="selectedNumbers"
                      v-for="(selectedNumber, i) in selectedNumbers"
                      :key="i + 100"
                    >
                      <v-layout row wrap justify-space-around>
                        <v-flex xs8 class="pt-2">
                          <span class="pa-1">{{
                            selectedNumber.toString()
                          }}</span>
                        </v-flex>
                        <v-flex xs3>
                          <v-text-field
                            type="number"
                            min="0"
                            style="font-size: 11px"
                            v-model="numberInputMoney[i]"
                            class="white"
                            placeholder="Birr"
                            :rules="rules"
                            hide-details="true"
                            single-line
                            outlined
                            @click="
                              dogCurrentMousePosition = i;
                              inWhichToAddAmount = 'number';
                            "
                            dense
                          ></v-text-field>
                        </v-flex>

                        <v-flex xs1 class="pr-3">
                          <a @click="removeSelectedNumbers(i)"
                            ><v-icon small color="error" right class="white"
                              >cancel</v-icon
                            ></a
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <!-- <v-flex xs4 class="mt-10 text-center">
             
            </v-flex> -->
                  </v-layout>
                </v-flex>

                <v-flex xs3>
                  <v-layout row wrap class="text-center mr-1">
                    <v-flex xs6 class="mt-0 pb-1">
                      <v-btn
                        :loading="gameLoading"
                        @click="getLastTwentyGames('dog')"
                        outlined
                        small
                        dark
                        text
                        class="white--text text-capitalize pa-4 pl-7 pr-7"
                      >
                        <span>Print 20 games</span>
                      </v-btn>
                    </v-flex>
                    <v-flex xs6 class="pa-1 pt-0 pb-1">
                      <v-form @submit.prevent="">
                        <v-text-field
                          style="font-size: 11px"
                          v-model="userIDToVerify"
                          :rules="[(v) => !!v || 'Required']"
                          label="Print prev ticket"
                          required
                          dense
                          outlined
                          single-line
                          :hide-details="true"
                          :loading="lastTickeloading"
                          class="white"
                          @change="getLastTicket"
                        ></v-text-field>
                      </v-form>
                    </v-flex>
                    <v-flex xs6 class="mt-0">
                      <a @click="applyToAll">
                        <v-card
                          dark
                          class="ma-1 mt-0 mb-2 pt-2 pb-2 pl-5 pr-5 customamount"
                          id="applyToAll"
                        >
                          <span>Apply to All</span>
                        </v-card>
                      </a>
                    </v-flex>
                    <v-flex xs6 class="mt-0">
                      <div class="ma-1 mt-0">
                        <v-text-field
                          v-model="selectedCustomAmount"
                          class="white"
                          placeholder="Birr"
                          :rules="rules"
                          :hide-details="true"
                          single-line
                          outlined
                          dense
                        ></v-text-field>
                      </div>
                    </v-flex>

                    <v-flex
                      dark
                      xs4
                      v-for="amount in customAmount"
                      :key="amount + 600"
                    >
                      <a @click="assignSelectedAmount(amount)">
                        <v-card
                          class="ma-1 mt-0 mb-1 pt-2 pb-2 pl-5 pr-5 customamount"
                          id="numbers"
                        >
                          <span>{{ amount }}</span>
                        </v-card>
                      </a>
                    </v-flex>

                    <v-flex dark xs4>
                      <a @click="clearAll">
                        <v-card
                          class="ma-1 mt-0 mb-1 pt-2 pb-2 pl-5 pr-5 customamount"
                          id="clearBtn"
                        >
                          <span>Clear</span>
                        </v-card>
                      </a>
                    </v-flex>

                    <v-flex xs12>
                      <v-card class="ma-1 pa-1 pt-0">
                        <div id="ticketListVertically">
                          <h4
                            v-if="isDuplicated"
                            class="red--text"
                            style="transform: scaleX(-1)"
                          >
                            Ticket Duplicated
                          </h4>
                          <v-form
                            @submit.prevent=""
                            style="transform: scaleX(-1)"
                          >
                            <v-card-text style="font-size: 11px">
                              <v-layout row wrap>
                                <v-flex
                                  xs12
                                  v-for="(lastGame, i) in lastTwoGames"
                                  :key="i"
                                >
                                  <v-layout
                                    row
                                    wrap
                                    v-for="(ticket, j) in lastGame.getTickets"
                                    :key="ticket.ticket_id"
                                    justify-space-around
                                  >
                                    <v-flex xs12 v-if="j != 0">
                                      <v-divider></v-divider>
                                    </v-flex>
                                    <v-flex
                                      xs12
                                      v-if="
                                        $store.state.cashier.cashier_id ==
                                        ticket.cashier_id
                                      "
                                    >
                                      <span>
                                        Game number:
                                        {{ lastGame.game_number }} </span
                                      ><br />
                                      <h3>Tickets:</h3>
                                      <span>
                                        {{ ticket.choosen_numbers }}
                                      </span>
                                      <br />

                                      <barcode
                                        class=""
                                        :display-value="false"
                                        :value="ticket.user_id"
                                        height="35px"
                                      >
                                        Some thing went wrong.
                                      </barcode>
                                      <div
                                        class="text-right"
                                        v-if="lastGame.game_status == 'pending'"
                                      >
                                        <v-btn
                                          v-if="j == 0"
                                          text
                                          small
                                          class="blue--text"
                                          @click="printAgain(ticket)"
                                        >
                                          <span class="text-capitalize"
                                            >Print again</span
                                          >
                                        </v-btn>
                                        <v-btn
                                          text
                                          small
                                          class="pink--text"
                                          @click="
                                            cancelDuplicatedTicket(
                                              ticket,
                                              lastGame.getTickets,
                                              j
                                            )
                                          "
                                        >
                                          <span class="text-capitalize"
                                            >Cancel ticket</span
                                          >
                                        </v-btn>
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-card-text>
                          </v-form>
                        </div>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>

              <v-dialog
                v-model="printDialog"
                persistent
                max-width="450"
                v-if="currentGame.length > 0"
              >
                <v-card>
                  <v-card-text
                    v-if="partnerBalance.ticket_layout == 'Layout 2'"
                    ref="printable"
                    style="font-family: Arial"
                  >
                    <!-- <img
                    height="50%"
                      contain
                      width="100%"
                      src="../assets/Keno-01.png"
                      alt=""
                    /> -->
                    <div
                      style="margin-left: 0px; color: black"
                      class="layoutWaterMark"
                    >
                      <div>
                        <div
                          style="
                            clear: right;
                            float: right;
                            font-size: 11px;
                            text-align: right;
                          "
                        >
                          <span>
                            {{ userNumber }}
                          </span>
                          <br />
                          <span>
                            {{ this.partnerBalance.shop_number }}
                          </span>
                          <br />
                          <span>
                            {{ this.$store.state.cashier.cashier_full_name }}
                          </span>
                          <br />

                          <span>
                            {{
                              new Date(Number(currentGame[0].game_date))
                                | formatDate
                            }}

                            (UTC +3)/(#{{ $store.state.kenoTicketNumber }})
                          </span>
                        </div>

                        <span style="font-weight: bold; margin-left: 0px">
                          <!-- {{ commonFeatures[0].brand_name }} K E N O -->
                          <img
                            height="30"
                            width="60"
                            v-if="partnerBalance.logo != 'Normal'"
                            :src="
                              require(`../assets/${partnerBalance.logo}.png`)
                            "
                          />
                        </span>
                      </div>
                      <p
                        style="
                          clear: left;
                          float: left;
                          font-size: 11px;
                          text-align: left;
                        "
                      >
                        Dog Racing {{ new Date(gameDate) | formatDate }} #{{
                          currentGame[0].game_number
                        }}
                      </p>

                      <table
                        style="
                          width: 100%;
                          text-align: left;
                          margin-top: 0;
                          font-size: 11px;
                        "
                      >
                        <tr>
                          <th
                            style="
                              padding-bottom: 4px;
                              border-bottom: 1px solid grey;
                              font-weight: normal;
                            "
                          >
                            Choosen
                          </th>
                          <th
                            style="
                              padding-bottom: 4px;
                              border-bottom: 1px solid grey;
                              font-weight: normal;
                            "
                          >
                            | &nbsp;&nbsp;Odds
                          </th>
                          <th
                            style="
                              padding-bottom: 4px;
                              border-bottom: 1px solid grey;
                              font-weight: normal;
                            "
                          >
                            | &nbsp;&nbsp;Stake
                          </th>

                          <th
                            style="
                              padding-bottom: 4px;
                              border-bottom: 1px solid grey;
                              font-weight: normal;
                            "
                          >
                            | &nbsp;&nbsp;Win
                          </th>
                        </tr>

                        <span
                          v-for="(selectedNumber, i) in selectedNumbers"
                          :key="i + 1000"
                        >
                          <tr>
                            <td
                              style="padding-top: 0px"
                              v-if="
                                selectedNumber.split('*')[0] != 'High' &&
                                selectedNumber.split('*')[0] != 'Low' &&
                                selectedNumber.split('*')[0] != 'Even' &&
                                selectedNumber.split('*')[0] != 'Odd'
                              "
                            >
                              {{
                                selectedNumber.split("*")[0] +
                                " " +
                                selectedNumber.split("*")[1]
                              }}
                            </td>
                            <td style="padding-top: 0px" v-else>
                              {{ selectedNumber.split("*")[0] }}
                            </td>
                            <td style="padding-top: 0px">
                              | &nbsp;&nbsp;{{ selectedNumber.split("*")[2] }}
                            </td>
                            <td style="padding-top: 0px">
                              | &nbsp;&nbsp;{{ numberInputMoney[i] }}
                            </td>

                            <td style="padding-top: 0px">
                              | &nbsp;&nbsp;{{
                                (
                                  parseFloat(numberInputMoney[i]) *
                                  parseFloat(selectedNumber.split("*")[2])
                                ).toFixed(2)
                              }}
                            </td>
                          </tr>
                        </span>
                      </table>
                      <table
                        style="width: 100%; font-size: 11px; margin-top: 5px"
                      >
                        <tr>
                          <td style="font-weight: bold; padding-top: 3px">
                            Total Stake
                          </td>
                          <td style="font-weight: bold; padding-top: 3px"></td>
                          <td style="font-weight: bold; padding-top: 3px"></td>
                          <td
                            style="
                              font-weight: bold;
                              padding-top: 3px;
                              text-align: right;
                            "
                          >
                            &nbsp;&nbsp;&nbsp;
                            <span style="font-size: 11px">Br </span
                            >{{ totalInputMoney.toFixed(2) }}
                          </td>
                        </tr>
                      </table>
                      <div
                        style="
                          border: 1px solid !important;
                          border-collapse: collapse !important;
                          border-radius: 2px !important;

                          overflow: hidden;
                        "
                      >
                        <table style="width: 100%; font-size: 11px">
                          <tr>
                            <td style="font-weight: bold; padding-top: 0px">
                              Min Payout (Incl Stake)
                            </td>
                            <td
                              style="font-weight: bold; padding-top: 0px"
                            ></td>
                            <td
                              style="font-weight: bold; padding-top: 0px"
                            ></td>
                            <td
                              style="
                                font-weight: bold;
                                padding-top: 0px;
                                text-align: right;
                              "
                            >
                              &nbsp;&nbsp;&nbsp;<span style="font-size: 11px"
                                >Br </span
                              >{{ totalInputMoney.toFixed(2) }}
                            </td>
                          </tr>

                          <tr>
                            <td style="font-weight: bold; padding-top: 0px">
                              Max Payout (Incl Stake)
                            </td>
                            <td
                              style="font-weight: bold; padding-top: 0px"
                            ></td>
                            <td
                              style="font-weight: bold; padding-top: 0px"
                            ></td>
                            <td
                              style="
                                font-weight: bold;
                                padding-top: 0px;
                                text-align: right;
                              "
                            >
                              &nbsp;&nbsp;&nbsp;<span style="font-size: 11px"
                                >Br </span
                              >{{ totalPossibleWin.toFixed(2) }}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div
                      style="
                        text-align: center;
                        font-size: 10px;
                        padding-top: 3px;
                      "
                    >
                      Valid Until:
                      {{
                        new Date(gameDate + 48 * 60 * 60 * 1000) | formatDate
                      }}
                    </div>
                    <table
                      style="font-size: 11px; text-align: center; width: 100%"
                    >
                      <tr>
                        <td
                          style="
                            padding-left: 3px;
                            padding-right: 3px;
                            text-align: center;
                          "
                        >
                          <div
                            style="
                              padding-left: 25px;

                              text-align: center;
                            "
                          >
                            <barcode
                              :display-value="false"
                              :value="userNumber"
                              height="50px"
                              margin="0px"
                            >
                              Some thing went wrong.
                            </barcode>
                          </div>
                          <div style="text-align: center; padding-right: 20px">
                            <span
                              >*
                              {{
                                userNumber
                                  .toString()
                                  .split("")
                                  .toString()
                                  .replaceAll(",", " &nbsp;&nbsp; ")
                              }}
                              *</span
                            >
                          </div>
                        </td>
                      </tr>
                    </table>

                    <div
                      v-if="commonFeatures.length > 0"
                      style="text-align: center"
                    >
                      <span
                        style="text-align: center; font-weight: bold"
                        v-if="commonFeatures.length > 0"
                      >
                        {{ commonFeatures[0].phone }}

                        {{ commonFeatures[0].others }}

                        {{ commonFeatures[0].brand_name }}
                      </span>
                    </div>
                  </v-card-text>

                  <v-card-text
                    v-if="partnerBalance.ticket_layout == 'Layout 1'"
                    ref="printable"
                    style="font-family: Arial"
                  >
                    <!-- <img
                  height="50%"
                    contain
                    width="100%"
                    src="../assets/Keno-01.png"
                    alt=""
                  /> -->
                    <div
                      style="margin-left: 0px; color: black"
                      class="layoutWaterMark"
                    >
                      <div>
                        <div
                          style="
                            clear: right;
                            float: right;
                            font-size: 11px;
                            text-align: right;
                          "
                        >
                          <span>
                            {{ userNumber }}
                          </span>
                          <br />
                          <span>
                            {{ this.partnerBalance.shop_number }}
                          </span>
                          <br />
                          <span>
                            {{ this.$store.state.cashier.cashier_full_name }}
                          </span>
                          <br />

                          <span>
                            {{
                              new Date(Number(currentGame[0].game_date))
                                | formatDate
                            }}

                            (UTC +3)/(#{{ $store.state.kenoTicketNumber }})
                          </span>
                        </div>
                        <span style="font-weight: bold; margin-left: 0px">
                          <!-- {{ commonFeatures[0].brand_name }} K E N O -->
                          <img
                            height="30"
                            width="60"
                            v-if="partnerBalance.logo != 'Normal'"
                            :src="
                              require(`../assets/${partnerBalance.logo}.png`)
                            "
                          />
                        </span>
                      </div>

                      <table
                        style="width: 100%; font-size: 11px"
                        v-if="
                          numberInputMoney.lengtth == selectedNumbers.lengtth
                        "
                      >
                        <span
                          v-for="(selectedNumber, i) in selectedNumbers"
                          :key="i + 1000"
                        >
                          <tr>
                            <td style="font-size: 11px; text-align: left">
                              <span
                                v-if="
                                  selectedNumber.split('*')[0].trim() ==
                                    'High' ||
                                  selectedNumber.split('*')[0].trim() == 'Low'
                                "
                                style="font-weight: bold; font-size: 11px"
                                >High/Low</span
                              >
                              <span
                                v-else-if="
                                  selectedNumber.split('*')[0].trim() ==
                                    'Even' ||
                                  selectedNumber.split('*')[0].trim() == 'Odd'
                                "
                                style="font-weight: bold; font-size: 11px"
                                >Even/Odd</span
                              >
                              <span
                                v-else
                                style="font-weight: bold; font-size: 11px"
                                >{{ selectedNumber.split("*")[0] }}</span
                              >

                              <br />
                              Dog Racing
                              {{ new Date(gameDate) | formatDate }} #{{
                                currentGame[0].game_number
                              }}
                              <br />
                              &nbsp;&nbsp;
                              <span
                                style="padding-top: 0px"
                                v-if="
                                  selectedNumber.split('*')[0] != 'High' &&
                                  selectedNumber.split('*')[0] != 'Low' &&
                                  selectedNumber.split('*')[0] != 'Even' &&
                                  selectedNumber.split('*')[0] != 'Odd'
                                "
                              >
                                {{
                                  selectedNumber.split("*")[0] +
                                  " " +
                                  selectedNumber.split("*")[1]
                                }}
                              </span>
                              <span style="padding-top: 0px" v-else>
                                {{ selectedNumber.split("*")[0] }}
                              </span>
                              &nbsp;&nbsp;{{ selectedNumber.split("*")[2] }}
                            </td>

                            <td
                              style="
                                text-align: right;
                                font-weight: bold;
                                padding-top: 0px;
                              "
                            >
                              <span style="font-size: 11px">Br </span
                              >{{ numberInputMoney[i] }}.00 <span><br /></span
                              ><span
                                ><br />
                                <br
                              /></span>
                            </td>
                          </tr>
                        </span>
                      </table>
                      <table
                        style="width: 100%; font-size: 11px; margin-top: 5px"
                      >
                        <tr>
                          <td style="font-weight: bold; padding-top: 3px">
                            Total Stake
                          </td>
                          <td style="font-weight: bold; padding-top: 3px"></td>
                          <td style="font-weight: bold; padding-top: 3px"></td>
                          <td
                            style="
                              font-weight: bold;
                              padding-top: 3px;
                              text-align: right;
                            "
                          >
                            &nbsp;&nbsp;&nbsp;
                            <span style="font-size: 11px">Br </span
                            >{{ totalInputMoney.toFixed(2) }}
                          </td>
                        </tr>
                      </table>
                      <div
                        style="
                          border: 1px solid !important;
                          border-collapse: collapse !important;
                          border-radius: 2px !important;

                          overflow: hidden;
                        "
                      >
                        <table style="width: 100%; font-size: 11px">
                          <tr>
                            <td style="font-weight: bold; padding-top: 0px">
                              Min Payout (Incl Stake)
                            </td>
                            <td
                              style="font-weight: bold; padding-top: 0px"
                            ></td>
                            <td
                              style="font-weight: bold; padding-top: 0px"
                            ></td>
                            <td
                              style="
                                font-weight: bold;
                                padding-top: 0px;
                                text-align: right;
                              "
                            >
                              &nbsp;&nbsp;&nbsp;<span style="font-size: 11px"
                                >Br </span
                              >{{ totalInputMoney.toFixed(2) }}
                            </td>
                          </tr>

                          <tr>
                            <td style="font-weight: bold; padding-top: 0px">
                              Max Payout (Incl Stake)
                            </td>
                            <td
                              style="font-weight: bold; padding-top: 0px"
                            ></td>
                            <td
                              style="font-weight: bold; padding-top: 0px"
                            ></td>
                            <td
                              style="
                                font-weight: bold;
                                padding-top: 0px;
                                text-align: right;
                              "
                            >
                              &nbsp;&nbsp;&nbsp;<span style="font-size: 11px"
                                >Br </span
                              >{{ totalPossibleWin.toFixed(2) }}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div
                      style="
                        text-align: center;
                        font-size: 10px;
                        padding-top: 3px;
                      "
                    >
                      Valid Until:
                      {{
                        new Date(gameDate + 48 * 60 * 60 * 1000) | formatDate
                      }}
                    </div>

                    <table
                      style="font-size: 11px; text-align: center; width: 100%"
                    >
                      <tr>
                        <td
                          style="
                            padding-left: 3px;
                            padding-right: 3px;
                            text-align: center;
                          "
                        >
                          <div
                            style="
                              padding-left: 25px;

                              text-align: center;
                            "
                          >
                            <barcode
                              :display-value="false"
                              :value="userNumber"
                              height="50px"
                              margin="0px"
                            >
                              Some thing went wrong.
                            </barcode>
                          </div>
                          <div style="text-align: center; padding-right: 20px">
                            <span
                              >*
                              {{
                                userNumber
                                  .toString()
                                  .split("")
                                  .toString()
                                  .replaceAll(",", " &nbsp;&nbsp; ")
                              }}
                              *</span
                            >
                          </div>
                        </td>
                      </tr>
                    </table>

                    <div
                      v-if="commonFeatures.length > 0"
                      style="text-align: center"
                    >
                      <span
                        style="text-align: center; font-weight: bold"
                        v-if="commonFeatures.length > 0"
                      >
                        {{ commonFeatures[0].phone }}

                        {{ commonFeatures[0].others }}

                        {{ commonFeatures[0].brand_name }}
                      </span>
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" class="text-capitalize" text>
                      Waiting...
                    </v-btn>
                    <v-btn
                      class="text-capitalize"
                      dark
                      color="red"
                      text
                      @click="printDialog = false"
                    >
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog
                v-model="isPlaying"
                persistent
                max-width="420"
                class="red"
                :retain-focus="false"
              >
                <p
                  class="headline white--text pink pa-5"
                  style="border-radius: 5px"
                >
                  <v-btn outlined rounded x-small @click="verifyDialog = true">
                    <span class="text-capitalize white--text">Verify</span>
                  </v-btn>

                  <v-btn
                    x-small
                    text
                    @click="whichToShow = 'horse'"
                    class="ml-10"
                  >
                    <span class="text-capitalize">
                      <v-img
                        contain
                        height="20px"
                        width="40px"
                        src="../assets/commonimages/DashingDerbyIcon.svg"
                      ></v-img>
                    </span>
                  </v-btn>

                  <v-btn x-small text @click="whichToShow = 'car'">
                    <span class="text-capitalize">
                      <v-img
                        contain
                        height="20px"
                        width="40px"
                        src="../assets/commonimages/MotorRacingIcon.svg"
                      ></v-img>
                    </span>
                  </v-btn>

                  <br />
                  Dogs are in running mode, please try later! <br />
                  <!-- <v-btn small text @click="whichToShow = 'Horse'">
                    <span
                      class="mr-2 text-capitalize white--text text--darken-1"
                      >Switch to Horse</span
                    >
                  </v-btn> -->
                </p>
              </v-dialog>

              <v-dialog
                v-model="verifyDialog"
                persistent
                max-width="420"
                :retain-focus="false"
              >
                <v-card class="text-center pt-5">
                  <v-card-title> Verify user </v-card-title>
                  <v-form @submit.prevent="">
                    <v-card-text>
                      <P
                        >Scan the Bar code or Enter user Id under the Bar
                        code</P
                      >
                      <v-text-field
                        autofocus
                        style="font-size: 11px"
                        v-model="userIDToVerify"
                        :rules="[(v) => !!v || 'Required']"
                        label="User ID or Bar code"
                        required
                        dense
                        outlined
                        prepend-icon="view_column"
                      ></v-text-field>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        :loading="verifyLoading"
                        color="green darken-1"
                        class="text-capitalize"
                        text
                        type="submit"
                        @click="verify"
                      >
                        verify
                      </v-btn>
                      <v-btn
                        class="text-capitalize"
                        dark
                        color="red"
                        text
                        @click="
                          verifyDialog = false;
                          userIDToVerify = '';
                        "
                      >
                        Cancel
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>

              <v-dialog v-model="reportTicketDialog" persistent max-width="420">
                <v-card class="text-center pt-5">
                  <v-card-title> Report ticket </v-card-title>
                  <v-form @submit.prevent="">
                    <v-card-text class="pl-5 pr-5">
                      <P
                        >Scan the Bar code or Enter user Id under the Bar
                        code</P
                      >
                      <v-text-field
                        autofocus
                        style="font-size: 11px"
                        v-model="userIdForReport"
                        :rules="[(v) => !!v || 'Required']"
                        label="User ID or Bar code"
                        required
                        dense
                        outlined
                        hide-details="true"
                        prepend-icon="view_column"
                      ></v-text-field>

                      <v-text-field
                        class="mt-3"
                        hide-details="true"
                        style="font-size: 11px"
                        v-model="reportReason"
                        :rules="[(v) => !!v || 'Required']"
                        label="Write a reason"
                        required
                        dense
                        outlined
                        prepend-icon="note"
                      ></v-text-field>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        :loading="reportLoading"
                        color="green darken-1"
                        class="text-capitalize"
                        text
                        type="submit"
                        @click="reportTicket"
                      >
                        report
                      </v-btn>
                      <v-btn
                        class="text-capitalize"
                        dark
                        color="red"
                        text
                        @click="
                          reportTicketDialog = false;
                          userIdForReport = '';
                        "
                      >
                        Cancel
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>

              <v-dialog
                v-model="winnersDialog"
                persistent
                max-width="420"
                :retain-focus="false"
              >
                <v-card class="text-center pt-5">
                  <v-card-title> Check ticket </v-card-title>

                  <v-card-text>
                    <P class="green--text" v-if="totalWinMoneyForOneUser > 0">
                      <v-icon class="green--text">check</v-icon>
                      User won total
                      <strong
                        v-if="verifyUserData[0].getJackpotHistory.length > 0"
                        >{{
                          (
                            totalWinMoneyForOneUser +
                            verifyUserData[0].getJackpotHistory[0].amount
                          ).toFixed(2)
                        }}</strong
                      >

                      <strong v-else>{{
                        totalWinMoneyForOneUser.toFixed(2)
                      }}</strong>
                      Birr <br /><br />
                      <p
                        v-if="verifyUserData[0].getJackpotHistory.length > 0"
                        class="green--text"
                      >
                        {{ verifyUserData[0].getJackpotHistory[0].jack_type }}:
                        {{
                          verifyUserData[0].getJackpotHistory[0].amount.toFixed(
                            2
                          )
                        }}
                        Birr Won
                      </p>

                      <div v-for="(wonList, i) in payableList" :key="i">
                        <span
                          class="red--text"
                          v-if="wonList.includes(' Loss ')"
                        >
                          {{ wonList }}
                        </span>

                        <span v-else>
                          {{ wonList }}
                        </span>
                      </div>
                    </P>
                    <P class="red--text" v-else
                      ><v-icon class="red--text">cancel</v-icon> User loss the
                      game!
                    </P>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      :loading="payLoading"
                      color="green darken-1"
                      class="text-capitalize"
                      text
                      type="submit"
                      v-if="totalWinMoneyForOneUser > 0"
                      @click="payWinnerAward"
                    >
                      Pay
                    </v-btn>
                    <v-btn
                      class="text-capitalize"
                      dark
                      color="red"
                      text
                      @click="winnersDialog = false"
                    >
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="cancelDialog" persistent max-width="420" top>
                <v-card class="text-center pt-5">
                  <v-card-title> Cancel Ticket </v-card-title>

                  <v-form @submit.prevent="">
                    <v-card-text>
                      <P
                        >Scan the Bar code or Enter user Id under the Bar to
                        cancel the ticket</P
                      >
                      <v-text-field
                        autofocus
                        style="font-size: 11px"
                        v-model="userIDToCancel"
                        :rules="[(v) => !!v || 'Required']"
                        label="User ID or Bar code"
                        required
                        dense
                        outlined
                        prepend-icon="view_column"
                      ></v-text-field>

                      <P>Are you sure you want to cancel this ticket?</P>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        :loading="cancelLoading"
                        color="green darken-1"
                        class="text-capitalize"
                        text
                        type="submit"
                        @click="cancelTicket"
                      >
                        Yes
                      </v-btn>
                      <v-btn
                        class="text-capitalize"
                        dark
                        color="red"
                        text
                        @click="cancelDialog = false"
                      >
                        No
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>

              <v-dialog
                v-model="comboDialog"
                persistent
                max-width="300"
                v-if="currentGame.length > 0"
              >
                <v-card class="text-center pt-1">
                  <v-card-title>
                    Combinations<v-spacer>
                      <v-btn
                        class="text-capitalize"
                        dark
                        color="red"
                        text
                        @click="
                          comboDialog = false;
                          comboDog = '';
                        "
                      >
                        Cancel
                      </v-btn></v-spacer
                    >
                  </v-card-title>

                  <v-card-text>
                    <h3 class="text-center">{{ comboDog }}</h3>

                    <v-layout row wrap>
                      <v-flex
                        xs4
                        class="pa-1"
                        v-for="(combo, i) in currentGame[0].howmany_dogs"
                        :key="i + 100"
                      >
                        <a
                          @click="comboDog = comboDog + '-' + (i + 1)"
                          v-if="!comboDog.includes(i + 1)"
                        >
                          <v-card class="text-center pt-4 pb-4">
                            <span>{{ i + 1 }}</span>
                          </v-card>
                        </a>
                      </v-flex>
                    </v-layout>
                    <div class="mt-2">
                      <v-layout row wrap>
                        <v-flex
                          v-if="comboDog.split('-').length == 2"
                          xs4
                          class="pa-1"
                        >
                          <a
                            @click="
                              selectedNumbers.push(
                                'Exacta*' +
                                  comboDog +
                                  '*' +
                                  (
                                    comboDogOdd.exacta *
                                    parseFloat(
                                      currentGame[0].odd_multiplier.split(',')[
                                        parseInt(comboDog.split('-')[0]) - 1
                                      ]
                                    )
                                  ).toFixed(0)
                              );
                              dogCurrentMousePosition =
                                selectedNumbers.length - 1;
                              inWhichToAddAmount = 'number';
                              comboDialog = false;
                              comboDog = '';
                            "
                          >
                            <v-card class="text-center pt-4 pb-4">
                              <span>Exacta </span> <br />
                              <span style="font-size: 11px">2 in order </span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex
                          v-if="comboDog.split('-').length == 2"
                          xs4
                          class="pa-1"
                        >
                          <a
                            @click="
                              selectedNumbers.push(
                                'Quinella*' +
                                  comboDog +
                                  '*' +
                                  (
                                    comboDogOdd.quinella *
                                    parseFloat(
                                      currentGame[0].odd_multiplier.split(',')[
                                        parseInt(comboDog.split('-')[0]) - 1
                                      ]
                                    )
                                  ).toFixed(0)
                              );
                              dogCurrentMousePosition =
                                selectedNumbers.length - 1;
                              inWhichToAddAmount = 'number';
                              comboDialog = false;
                              comboDog = '';
                            "
                          >
                            <v-card class="text-center pt-4 pb-4">
                              <span>Quinella</span><br />
                              <span style="font-size: 11px">2 any order </span>
                            </v-card>
                          </a>
                        </v-flex>

                        <v-flex
                          v-if="comboDog.split('-').length == 2"
                          xs4
                          class="pa-1"
                        >
                          <a
                            @click="
                              selectedNumbers.push(
                                'Swinger*' +
                                  comboDog +
                                  '*' +
                                  (
                                    comboDogOdd.swinger *
                                    parseFloat(
                                      currentGame[0].odd_multiplier.split(',')[
                                        parseInt(comboDog.split('-')[0]) - 1
                                      ]
                                    )
                                  ).toFixed(0)
                              );
                              dogCurrentMousePosition =
                                selectedNumbers.length - 1;
                              inWhichToAddAmount = 'number';
                              comboDialog = false;
                              comboDog = '';
                            "
                          >
                            <v-card class="text-center pt-4 pb-4">
                              <span>Swinger</span>
                              <br />
                              <span style="font-size: 11px">2 in three </span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex
                          v-if="comboDog.split('-').length == 3"
                          xs4
                          class="pa-1"
                        >
                          <a
                            @click="
                              selectedNumbers.push(
                                'Trifecta*' +
                                  comboDog +
                                  '*' +
                                  (
                                    comboDogOdd.trifecta *
                                    parseFloat(
                                      currentGame[0].odd_multiplier.split(',')[
                                        parseInt(comboDog.split('-')[0]) - 1
                                      ]
                                    )
                                  ).toFixed(0)
                              );
                              dogCurrentMousePosition =
                                selectedNumbers.length - 1;
                              inWhichToAddAmount = 'number';
                              comboDialog = false;
                              comboDog = '';
                            "
                          >
                            <v-card class="text-center pt-4 pb-4">
                              <span>Trifecta</span><br />
                              <span style="font-size: 11px">3 in order </span>
                            </v-card>
                          </a>
                        </v-flex>

                        <v-flex
                          v-if="comboDog.split('-').length == 3"
                          xs4
                          class="pa-1"
                        >
                          <a
                            @click="
                              selectedNumbers.push(
                                'Trio*' +
                                  comboDog +
                                  '*' +
                                  (
                                    comboDogOdd.trio *
                                    parseFloat(
                                      currentGame[0].odd_multiplier.split(',')[
                                        parseInt(comboDog.split('-')[0]) - 1
                                      ]
                                    )
                                  ).toFixed(0)
                              );
                              dogCurrentMousePosition =
                                selectedNumbers.length - 1;
                              inWhichToAddAmount = 'number';
                              comboDialog = false;
                              comboDog = '';
                            "
                          >
                            <v-card class="text-center pt-4 pb-4">
                              <span>Trio</span><br />
                              <span style="font-size: 11px">3 any order </span>
                            </v-card>
                          </a>
                        </v-flex>
                      </v-layout>
                    </div>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </div>
            <div v-if="whichToShow == 'horse'">
              <v-app-bar elevation="0" app id="navBar" dark>
                <v-layout row wrap class="" style="font-size: 14px">
                  <v-flex xs2 class="" v-if="HorseCurrentGame.length > 0">
                    <p>GAME# {{ HorseCurrentGame[0].game_number }}</p>
                  </v-flex>
                  <v-flex xs2 class="mt-2 mr-2 pr-1 pl-1">
                    <p>
                      <v-progress-linear
                        v-model="HorseTimePercent"
                        height="8"
                      ></v-progress-linear>
                    </p>
                  </v-flex>
                  <v-flex
                    class="red--text"
                    xs2
                    v-if="HorseMinutes == '00' && parseInt(HorseSeconds) <= 20"
                  >
                    <p>GAME CLOSES IN {{ HorseMinutes }}:{{ HorseSeconds }}</p>
                  </v-flex>

                  <v-flex class="" xs2 v-else>
                    <p>GAME CLOSES IN {{ HorseMinutes }}:{{ HorseSeconds }}</p>
                  </v-flex>
                  <v-flex xs1 class="pl-2">
                    <v-btn
                      outlined
                      rounded
                      x-small
                      @click="HorseVerifyDialog = true"
                    >
                      <span class="text-capitalize">Verify</span>
                    </v-btn>
                  </v-flex>
                  <v-flex xs1 class="pl-2">
                    <v-btn
                      outlined
                      rounded
                      x-small
                      @click="HorseCancelDialog = true"
                    >
                      <span class="text-capitalize">Cancel</span>
                    </v-btn>
                  </v-flex>

                  <v-flex xs1 class="pl-2">
                    <v-btn text x-small @click="tmhPopUp = true">
                      <span class="text-capitalize">TMH </span>
                    </v-btn>
                  </v-flex>
                </v-layout>

                <v-btn small text>
                  <span class="mr-2 text-capitalize">{{ whichToShow }} </span>
                </v-btn>

                <v-btn small text @click="HorseReportTicketDialog = true">
                  <span class="mr-2 text-capitalize">Report ticket</span>
                </v-btn>
                <v-btn small text @click="logoutClicked">
                  <span class="mr-2 text-capitalize">Logout</span>
                </v-btn>
                <a @click="helpDialog = true">Help</a>
              </v-app-bar>

              <v-layout row wrap class="ml-1 mt-0 pb-3">
                <v-flex xs6 class="" v-if="HorseCurrentGame.length > 0">
                  <v-layout
                    row
                    wrap
                    class="text-center mr-3"
                    justify-space-around
                  >
                    <v-flex
                      xs12
                      v-if="partnerBalance.tempo_package <= 1000"
                      class="pa-1 red--text"
                    >
                      <span style="font-size: 14px"
                        >Before the system shuts down, please let your manager
                        know that your package is low.
                      </span>
                    </v-flex>

                    <v-layout
                      row
                      wrap
                      v-for="(list, i) in HorseCurrentGame[0].howmany_dogs"
                      :key="i"
                      justify-space-around
                      class="white--text"
                    >
                      <v-flex xs6 v-if="i == 0"> </v-flex>
                      <v-flex xs2 v-if="i == 0" class="text-left pl-2">
                        <span class="text-capitalize">win</span>
                      </v-flex>

                      <v-flex xs2 v-if="i == 0" class="text-left pl-2">
                        <span class="text-capitalize">place</span>
                      </v-flex>

                      <v-flex xs2 v-if="i == 0" class="text-left pl-2">
                        <span class="ttext-capitalize">Combos</span>
                      </v-flex>
                      <v-flex v-if="i == 0" xs12 class=""> </v-flex>
                      <v-flex xs1 class="pt-2">{{ i + 1 }}</v-flex>
                      <v-flex xs5>
                        <v-img
                          height="30"
                          width="100"
                          contain
                          :src="
                            require(`../assets/horseimage/${
                              HorseCurrentGame[0].img.split(',')[i]
                            }`)
                          "
                        >
                        </v-img>
                      </v-flex>
                      <v-flex xs2 class="text-left">
                        <v-btn
                          @click="
                            selectNumber(
                              'Win*' +
                                (i + 1) +
                                '*' +
                                parseFloat(
                                  HorseCurrentGame[0].winplace_odd_multiplier
                                    .split(',')
                                    [i].split(':')[0]
                                ).toFixed(2)
                            )
                          "
                          id="dogbtn"
                          text
                          outlined
                          style="background-color: rgba(41, 57, 73, 255)"
                        >
                          <span class="white--text">{{
                            parseFloat(
                              HorseCurrentGame[0].winplace_odd_multiplier
                                .split(",")
                                [i].split(":")[0]
                            ).toFixed(2)
                          }}</span>
                        </v-btn>
                      </v-flex>

                      <v-flex xs2 class="text-left">
                        <v-btn
                          @click="
                            selectNumber(
                              'Place*' +
                                (i + 1) +
                                '*' +
                                parseFloat(
                                  HorseCurrentGame[0].winplace_odd_multiplier
                                    .split(',')
                                    [i].split(':')[1]
                                ).toFixed(2)
                            )
                          "
                          text
                          outlined
                          style="background-color: rgba(41, 57, 73, 255)"
                        >
                          <span class="white--text">{{
                            parseFloat(
                              HorseCurrentGame[0].winplace_odd_multiplier
                                .split(",")
                                [i].split(":")[1]
                            ).toFixed(2)
                          }}</span>
                        </v-btn>
                      </v-flex>

                      <v-flex xs2 class="text-left">
                        <v-btn
                          @click="getHorseCombo(i + 1)"
                          text
                          outlined
                          style="background-color: rgba(41, 57, 73, 255)"
                        >
                          <span class="white--text text-capitalize"
                            >Horse# {{ i + 1 }}</span
                          >
                        </v-btn>
                      </v-flex>
                      <v-flex xs12 class="white--text pb-1">
                        <v-divider :light="true"></v-divider>
                        <!-- <hr /> -->
                      </v-flex>
                    </v-layout>

                    <v-flex xs8 class="mt-2">
                      <v-layout row wrap class="">
                        <v-flex xs2 class="ma-1 text-center" id="numbers">
                          <div class="pt-2 pb-2" id="selectedNum">
                            <a
                              @click="
                                selectNumber(
                                  'Even*Even*' +
                                    parseFloat(
                                      HorseCurrentGame[0].even_odd_multi.split(
                                        ','
                                      )[2]
                                    ).toFixed(2)
                                )
                              "
                            >
                              <div>
                                <span class="pa-1 white--text">Even</span>
                              </div>
                            </a>
                          </div>
                        </v-flex>
                        <v-flex xs2 class="ma-1 text-center" id="numbers">
                          <div class="pt-2 pb-2" id="selectedNum">
                            <a
                              @click="
                                selectNumber(
                                  'Odd*Odd*' +
                                    parseFloat(
                                      HorseCurrentGame[0].even_odd_multi.split(
                                        ','
                                      )[3]
                                    ).toFixed(2)
                                )
                              "
                            >
                              <div>
                                <span class="pa-1 white--text">Odd</span>
                              </div>
                            </a>
                          </div>
                        </v-flex>
                        <v-flex xs2 class="ma-1 text-center" id="numbers">
                          <div class="pt-2 pb-2" id="selectedNum">
                            <a
                              @click="
                                selectNumber(
                                  'High*High*' +
                                    parseFloat(
                                      HorseCurrentGame[0].even_odd_multi.split(
                                        ','
                                      )[0]
                                    ).toFixed(2)
                                )
                              "
                            >
                              <div>
                                <span class="pa-1 white--text">High</span>
                              </div>
                            </a>
                          </div>
                        </v-flex>
                        <v-flex xs2 class="ma-1 text-center" id="numbers">
                          <div class="pt-2 pb-2" id="selectedNum">
                            <a
                              @click="
                                selectNumber(
                                  'Low*Low*' +
                                    parseFloat(
                                      HorseCurrentGame[0].even_odd_multi.split(
                                        ','
                                      )[1]
                                    ).toFixed(2)
                                )
                              "
                            >
                              <div>
                                <span class="pa-1 white--text">Low</span>
                              </div>
                            </a>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>

                <v-flex xs3>
                  <v-layout
                    row
                    wrap
                    class="mr-1"
                    v-if="
                      selectedHorseNumbers.length > 0 ||
                      selectedEvenOddAndHighLow.length > 0
                    "
                  >
                    <!-- <v-flex xs12>
              <p style="font-size: 20px" class="white--text">
                Selected Numbers
              </p>
            </v-flex> -->
                    <v-flex
                      xs12
                      class="grey mb-1 ml-0 pt-2 pb-2"
                      id="selectedNumbers"
                    >
                      <v-layout row wrap justify-space-around>
                        <v-flex xs6 class="pl-8 pt-1">
                          <a
                            class="pa-1 pt-2 pb-3 white--text"
                            dark
                            @click="
                              selectedHorseNumbers = [];
                              HorseInputMoney = [];
                            "
                          >
                            <span class="text-capitalize">Clear</span></a
                          >
                        </v-flex>

                        <v-flex xs6 class="text-center pl-3">
                          <a
                            class="pa-1 pt-2 pb-3"
                            @click="horseRecietOverview('')"
                          >
                            <v-icon large class="white--text">print</v-icon>
                          </a>
                        </v-flex>
                      </v-layout>

                      <!-- <v-btn
                id="printBtn"
                text
                dark
                class="pl-10 pr-10 red--text"
                v-if="selectedNumbers.length > 0"
                @click="selectedNumbers = []"
              >
                <span class="text-capitalize">Cancel</span>
              </v-btn> -->
                    </v-flex>
                    <v-flex
                      xs12
                      class="grey mb-1 ml-0 pt-1 pb-1"
                      id="selectedNumbers"
                      v-for="(selectedNumber, i) in selectedHorseNumbers"
                      :key="i + 100"
                    >
                      <v-layout row wrap justify-space-around>
                        <v-flex xs8 class="pt-2">
                          <span class="pa-1">{{
                            selectedNumber.toString()
                          }}</span>
                        </v-flex>
                        <v-flex xs3>
                          <v-text-field
                            type="number"
                            min="0"
                            style="font-size: 11px"
                            v-model="HorseInputMoney[i]"
                            class="white"
                            placeholder="Birr"
                            :rules="rules"
                            hide-details="true"
                            single-line
                            outlined
                            @click="
                              horseCurrentMousePosition = i;
                              inWhichToAddAmount = 'number';
                            "
                            dense
                          ></v-text-field>
                        </v-flex>

                        <v-flex xs1 class="pr-3">
                          <a @click="removeSelectedHorseNumbers(i)"
                            ><v-icon small color="error" right class="white"
                              >cancel</v-icon
                            ></a
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <!-- <v-flex xs4 class="mt-10 text-center">
             
            </v-flex> -->
                  </v-layout>
                </v-flex>

                <v-flex xs3>
                  <v-layout row wrap class="text-center mr-1">
                    <v-flex xs6 class="mt-0 pb-1">
                      <v-btn
                        :loading="gameLoading"
                        @click="getLastTwentyGames('horse')"
                        outlined
                        small
                        dark
                        text
                        class="white--text text-capitalize pa-4 pl-7 pr-7"
                      >
                        <span>Print 20 games</span>
                      </v-btn>
                    </v-flex>
                    <v-flex xs6 class="pa-1 pt-0 pb-1">
                      <v-form @submit.prevent="">
                        <v-text-field
                          style="font-size: 11px"
                          v-model="HorseUserIDToVerify"
                          :rules="[(v) => !!v || 'Required']"
                          label="Print prev ticket"
                          required
                          dense
                          outlined
                          single-line
                          :hide-details="true"
                          :loading="lastTickeloading"
                          class="white"
                          @change="getLastHorseTicket"
                        ></v-text-field>
                      </v-form>
                    </v-flex>
                    <v-flex xs6 class="mt-0">
                      <a @click="horseApplyToAll">
                        <v-card
                          dark
                          class="ma-1 mt-0 mb-2 pt-2 pb-2 pl-5 pr-5 customamount"
                          id="applyToAll"
                        >
                          <span>Apply to All</span>
                        </v-card>
                      </a>
                    </v-flex>
                    <v-flex xs6 class="mt-0">
                      <div class="ma-1 mt-0">
                        <v-text-field
                          v-model="horseSelectedCustomAmount"
                          class="white"
                          placeholder="Birr"
                          :rules="rules"
                          :hide-details="true"
                          single-line
                          outlined
                          dense
                        ></v-text-field>
                      </div>
                    </v-flex>

                    <v-flex
                      dark
                      xs4
                      v-for="amount in customAmount"
                      :key="amount + 600"
                    >
                      <a @click="horseAssignSelectedAmount(amount)">
                        <v-card
                          class="ma-1 mt-0 mb-1 pt-2 pb-2 pl-5 pr-5 customamount"
                          id="numbers"
                        >
                          <span>{{ amount }}</span>
                        </v-card>
                      </a>
                    </v-flex>

                    <v-flex dark xs4>
                      <a @click="horseClearAll">
                        <v-card
                          class="ma-1 mt-0 mb-1 pt-2 pb-2 pl-5 pr-5 customamount"
                          id="clearBtn"
                        >
                          <span>Clear</span>
                        </v-card>
                      </a>
                    </v-flex>

                    <v-flex xs12>
                      <v-card class="ma-1 pa-1 pt-0">
                        <div id="ticketListVertically">
                          <h4
                            v-if="HorseIsDuplicated"
                            class="red--text"
                            style="transform: scaleX(-1)"
                          >
                            Ticket Duplicated
                          </h4>
                          <v-form
                            @submit.prevent=""
                            style="transform: scaleX(-1)"
                          >
                            <v-card-text style="font-size: 11px">
                              <v-layout row wrap>
                                <v-flex
                                  xs12
                                  v-for="(lastGame, i) in HorseLastTwoGames"
                                  :key="i"
                                >
                                  <v-layout
                                    row
                                    wrap
                                    v-for="(ticket, j) in lastGame.getTickets"
                                    :key="ticket.ticket_id"
                                    justify-space-around
                                  >
                                    <v-flex xs12 v-if="j != 0">
                                      <v-divider></v-divider>
                                    </v-flex>
                                    <v-flex
                                      xs12
                                      v-if="
                                        $store.state.cashier.cashier_id ==
                                        ticket.cashier_id
                                      "
                                    >
                                      <span>
                                        Game number:
                                        {{ lastGame.game_number }} </span
                                      ><br />
                                      <h3>Tickets:</h3>
                                      <span>
                                        {{ ticket.choosen_numbers }}
                                      </span>
                                      <br />

                                      <barcode
                                        class=""
                                        :display-value="false"
                                        :value="ticket.user_id"
                                        height="35px"
                                      >
                                        Some thing went wrong.
                                      </barcode>
                                      <div
                                        class="text-right"
                                        v-if="lastGame.game_status == 'pending'"
                                      >
                                        <v-btn
                                          v-if="j == 0"
                                          text
                                          small
                                          class="blue--text"
                                          @click="horsePrintAgain(ticket)"
                                        >
                                          <span class="text-capitalize"
                                            >Print again</span
                                          >
                                        </v-btn>
                                        <v-btn
                                          text
                                          small
                                          class="pink--text"
                                          @click="
                                            horseCancelDuplicatedTicket(
                                              ticket,
                                              lastGame.getTickets,
                                              j
                                            )
                                          "
                                        >
                                          <span class="text-capitalize"
                                            >Cancel ticket</span
                                          >
                                        </v-btn>
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-card-text>
                          </v-form>
                        </div>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>

              <v-dialog
                v-model="HorsePrintDialog"
                persistent
                max-width="450"
                v-if="HorseCurrentGame.length > 0"
              >
                <v-card>
                  <v-card-text
                    v-if="partnerBalance.ticket_layout == 'Layout 2'"
                    ref="horsePrintable"
                    style="font-family: Arial"
                  >
                    <!-- <img
                    height="50%"
                      contain
                      width="100%"
                      src="../assets/Keno-01.png"
                      alt=""
                    /> -->
                    <div
                      style="margin-left: 0px; color: black"
                      class="layoutWaterMark"
                    >
                      <div>
                        <div
                          style="
                            clear: right;
                            float: right;
                            font-size: 11px;
                            text-align: right;
                          "
                        >
                          <span>
                            {{ HorseUserNumber }}
                          </span>
                          <br />
                          <span>
                            {{ this.partnerBalance.shop_number }}
                          </span>
                          <br />
                          <span>
                            {{ this.$store.state.cashier.cashier_full_name }}
                          </span>
                          <br />

                          <span>
                            {{
                              new Date(Number(HorseCurrentGame[0].game_date))
                                | formatDate
                            }}

                            (UTC +3)/(#{{ $store.state.HorseTicketNumber }})
                          </span>
                        </div>

                        <span style="font-weight: bold; margin-left: 0px">
                          <!-- {{ commonFeatures[0].brand_name }} K E N O -->
                          <img
                            height="30"
                            width="60"
                            v-if="partnerBalance.logo != 'Normal'"
                            :src="
                              require(`../assets/${partnerBalance.logo}.png`)
                            "
                          />
                        </span>
                      </div>
                      <p
                        style="
                          clear: left;
                          float: left;
                          font-size: 11px;
                          text-align: left;
                        "
                      >
                        Dog Racing
                        {{ new Date(HorseGameDate) | formatDate }} #{{
                          HorseCurrentGame[0].game_number
                        }}
                      </p>

                      <table
                        style="
                          width: 100%;
                          text-align: left;
                          margin-top: 0;
                          font-size: 11px;
                        "
                      >
                        <tr>
                          <th
                            style="
                              padding-bottom: 4px;
                              border-bottom: 1px solid grey;
                              font-weight: normal;
                            "
                          >
                            Choosen
                          </th>
                          <th
                            style="
                              padding-bottom: 4px;
                              border-bottom: 1px solid grey;
                              font-weight: normal;
                            "
                          >
                            | &nbsp;&nbsp;Odds
                          </th>
                          <th
                            style="
                              padding-bottom: 4px;
                              border-bottom: 1px solid grey;
                              font-weight: normal;
                            "
                          >
                            | &nbsp;&nbsp;Stake
                          </th>

                          <th
                            style="
                              padding-bottom: 4px;
                              border-bottom: 1px solid grey;
                              font-weight: normal;
                            "
                          >
                            | &nbsp;&nbsp;Win
                          </th>
                        </tr>

                        <span
                          v-for="(selectedNumber, i) in selectedHorseNumbers"
                          :key="i + 1000"
                        >
                          <tr>
                            <td
                              style="padding-top: 0px"
                              v-if="
                                selectedNumber.split('*')[0] != 'High' &&
                                selectedNumber.split('*')[0] != 'Low' &&
                                selectedNumber.split('*')[0] != 'Even' &&
                                selectedNumber.split('*')[0] != 'Odd'
                              "
                            >
                              {{
                                selectedNumber.split("*")[0] +
                                " " +
                                selectedNumber.split("*")[1]
                              }}
                            </td>
                            <td style="padding-top: 0px" v-else>
                              {{ selectedNumber.split("*")[0] }}
                            </td>
                            <td style="padding-top: 0px">
                              | &nbsp;&nbsp;{{ selectedNumber.split("*")[2] }}
                            </td>
                            <td style="padding-top: 0px">
                              | &nbsp;&nbsp;{{ HorseInputMoney[i] }}
                            </td>

                            <td style="padding-top: 0px">
                              | &nbsp;&nbsp;{{
                                (
                                  parseFloat(HorseInputMoney[i]) *
                                  parseFloat(selectedNumber.split("*")[2])
                                ).toFixed(2)
                              }}
                            </td>
                          </tr>
                        </span>
                      </table>
                      <table
                        style="width: 100%; font-size: 11px; margin-top: 5px"
                      >
                        <tr>
                          <td style="font-weight: bold; padding-top: 3px">
                            Total Stake
                          </td>
                          <td style="font-weight: bold; padding-top: 3px"></td>
                          <td style="font-weight: bold; padding-top: 3px"></td>
                          <td
                            style="
                              font-weight: bold;
                              padding-top: 3px;
                              text-align: right;
                            "
                          >
                            &nbsp;&nbsp;&nbsp;
                            <span style="font-size: 11px">Br </span
                            >{{ HorseTotalInputMoney.toFixed(2) }}
                          </td>
                        </tr>
                      </table>
                      <div
                        style="
                          border: 1px solid !important;
                          border-collapse: collapse !important;
                          border-radius: 2px !important;

                          overflow: hidden;
                        "
                      >
                        <table style="width: 100%; font-size: 11px">
                          <tr>
                            <td style="font-weight: bold; padding-top: 0px">
                              Min Payout (Incl Stake)
                            </td>
                            <td
                              style="font-weight: bold; padding-top: 0px"
                            ></td>
                            <td
                              style="font-weight: bold; padding-top: 0px"
                            ></td>
                            <td
                              style="
                                font-weight: bold;
                                padding-top: 0px;
                                text-align: right;
                              "
                            >
                              &nbsp;&nbsp;&nbsp;<span style="font-size: 11px"
                                >Br </span
                              >{{ HorseTotalInputMoney.toFixed(2) }}
                            </td>
                          </tr>

                          <tr>
                            <td style="font-weight: bold; padding-top: 0px">
                              Max Payout (Incl Stake)
                            </td>
                            <td
                              style="font-weight: bold; padding-top: 0px"
                            ></td>
                            <td
                              style="font-weight: bold; padding-top: 0px"
                            ></td>
                            <td
                              style="
                                font-weight: bold;
                                padding-top: 0px;
                                text-align: right;
                              "
                            >
                              &nbsp;&nbsp;&nbsp;<span style="font-size: 11px"
                                >Br </span
                              >{{ HorseTotalPossibleWin.toFixed(2) }}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div
                      style="
                        text-align: center;
                        font-size: 10px;
                        padding-top: 3px;
                      "
                    >
                      Valid Until:
                      {{
                        new Date(HorseGameDate + 48 * 60 * 60 * 1000)
                          | formatDate
                      }}
                    </div>
                    <table
                      style="font-size: 11px; text-align: center; width: 100%"
                    >
                      <tr>
                        <td
                          style="
                            padding-left: 3px;
                            padding-right: 3px;
                            text-align: center;
                          "
                        >
                          <div
                            style="
                              padding-left: 25px;

                              text-align: center;
                            "
                          >
                            <barcode
                              :display-value="false"
                              :value="HorseUserNumber"
                              height="50px"
                              margin="0px"
                            >
                              Some thing went wrong.
                            </barcode>
                          </div>
                          <div style="text-align: center; padding-right: 20px">
                            <span
                              >*
                              {{
                                HorseUserNumber.toString()
                                  .split("")
                                  .toString()
                                  .replaceAll(",", " &nbsp;&nbsp; ")
                              }}
                              *</span
                            >
                          </div>
                        </td>
                      </tr>
                    </table>

                    <div
                      v-if="commonFeatures.length > 0"
                      style="text-align: center"
                    >
                      <span
                        style="text-align: center; font-weight: bold"
                        v-if="commonFeatures.length > 0"
                      >
                        {{ commonFeatures[0].phone }}

                        {{ commonFeatures[0].others }}

                        {{ commonFeatures[0].brand_name }}
                      </span>
                    </div>
                  </v-card-text>

                  <v-card-text
                    v-if="partnerBalance.ticket_layout == 'Layout 1'"
                    ref="horsePrintable"
                    style="font-family: Arial"
                  >
                    <!-- <img
                  height="50%"
                    contain
                    width="100%"
                    src="../assets/Keno-01.png"
                    alt=""
                  /> -->
                    <div
                      style="margin-left: 0px; color: black"
                      class="layoutWaterMark"
                    >
                      <div>
                        <div
                          style="
                            clear: right;
                            float: right;
                            font-size: 11px;
                            text-align: right;
                          "
                        >
                          <span>
                            {{ HorseUserNumber }}
                          </span>
                          <br />
                          <span>
                            {{ this.partnerBalance.shop_number }}
                          </span>
                          <br />
                          <span>
                            {{ this.$store.state.cashier.cashier_full_name }}
                          </span>
                          <br />

                          <span>
                            {{
                              new Date(Number(HorseCurrentGame[0].game_date))
                                | formatDate
                            }}

                            (UTC +3)/(#{{ $store.state.HorseTicketNumber }})
                          </span>
                        </div>
                        <span style="font-weight: bold; margin-left: 0px">
                          <!-- {{ commonFeatures[0].brand_name }} K E N O -->
                          <img
                            height="30"
                            width="60"
                            v-if="partnerBalance.logo != 'Normal'"
                            :src="
                              require(`../assets/${partnerBalance.logo}.png`)
                            "
                          />
                        </span>
                      </div>

                      <table
                        style="width: 100%; font-size: 11px"
                        v-if="
                          HorseInputMoney.lengtth ==
                          selectedHorseNumbers.lengtth
                        "
                      >
                        <span
                          v-for="(selectedNumber, i) in selectedHorseNumbers"
                          :key="i + 1000"
                        >
                          <tr>
                            <td style="font-size: 11px; text-align: left">
                              <span
                                v-if="
                                  selectedNumber.split('*')[0].trim() ==
                                    'High' ||
                                  selectedNumber.split('*')[0].trim() == 'Low'
                                "
                                style="font-weight: bold; font-size: 11px"
                                >High/Low</span
                              >
                              <span
                                v-else-if="
                                  selectedNumber.split('*')[0].trim() ==
                                    'Even' ||
                                  selectedNumber.split('*')[0].trim() == 'Odd'
                                "
                                style="font-weight: bold; font-size: 11px"
                                >Even/Odd</span
                              >
                              <span
                                v-else
                                style="font-weight: bold; font-size: 11px"
                                >{{ selectedNumber.split("*")[0] }}</span
                              >

                              <br />
                              Horse Racing
                              {{ new Date(HorseGameDate) | formatDate }} #{{
                                HorseCurrentGame[0].game_number
                              }}
                              <br />
                              &nbsp;&nbsp;
                              <span
                                style="padding-top: 0px"
                                v-if="
                                  selectedNumber.split('*')[0] != 'High' &&
                                  selectedNumber.split('*')[0] != 'Low' &&
                                  selectedNumber.split('*')[0] != 'Even' &&
                                  selectedNumber.split('*')[0] != 'Odd'
                                "
                              >
                                {{
                                  selectedNumber.split("*")[0] +
                                  " " +
                                  selectedNumber.split("*")[1]
                                }}
                              </span>
                              <span style="padding-top: 0px" v-else>
                                {{ selectedNumber.split("*")[0] }}
                              </span>
                              &nbsp;&nbsp;{{ selectedNumber.split("*")[2] }}
                            </td>

                            <td
                              style="
                                text-align: right;
                                font-weight: bold;
                                padding-top: 0px;
                              "
                            >
                              <span style="font-size: 11px">Br </span
                              >{{ HorseInputMoney[i] }}.00 <span><br /></span
                              ><span
                                ><br />
                                <br
                              /></span>
                            </td>
                          </tr>
                        </span>
                      </table>
                      <table
                        style="width: 100%; font-size: 11px; margin-top: 5px"
                      >
                        <tr>
                          <td style="font-weight: bold; padding-top: 3px">
                            Total Stake
                          </td>
                          <td style="font-weight: bold; padding-top: 3px"></td>
                          <td style="font-weight: bold; padding-top: 3px"></td>
                          <td
                            style="
                              font-weight: bold;
                              padding-top: 3px;
                              text-align: right;
                            "
                          >
                            &nbsp;&nbsp;&nbsp;
                            <span style="font-size: 11px">Br </span
                            >{{ HorseTotalInputMoney.toFixed(2) }}
                          </td>
                        </tr>
                      </table>
                      <div
                        style="
                          border: 1px solid !important;
                          border-collapse: collapse !important;
                          border-radius: 2px !important;

                          overflow: hidden;
                        "
                      >
                        <table style="width: 100%; font-size: 11px">
                          <tr>
                            <td style="font-weight: bold; padding-top: 0px">
                              Min Payout (Incl Stake)
                            </td>
                            <td
                              style="font-weight: bold; padding-top: 0px"
                            ></td>
                            <td
                              style="font-weight: bold; padding-top: 0px"
                            ></td>
                            <td
                              style="
                                font-weight: bold;
                                padding-top: 0px;
                                text-align: right;
                              "
                            >
                              &nbsp;&nbsp;&nbsp;<span style="font-size: 11px"
                                >Br </span
                              >{{ HorseTotalInputMoney.toFixed(2) }}
                            </td>
                          </tr>

                          <tr>
                            <td style="font-weight: bold; padding-top: 0px">
                              Max Payout (Incl Stake)
                            </td>
                            <td
                              style="font-weight: bold; padding-top: 0px"
                            ></td>
                            <td
                              style="font-weight: bold; padding-top: 0px"
                            ></td>
                            <td
                              style="
                                font-weight: bold;
                                padding-top: 0px;
                                text-align: right;
                              "
                            >
                              &nbsp;&nbsp;&nbsp;<span style="font-size: 11px"
                                >Br </span
                              >{{ HorseTotalPossibleWin.toFixed(2) }}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div
                      style="
                        text-align: center;
                        font-size: 10px;
                        padding-top: 3px;
                      "
                    >
                      Valid Until:
                      {{
                        new Date(HorseGameDate + 48 * 60 * 60 * 1000)
                          | formatDate
                      }}
                    </div>

                    <table
                      style="font-size: 11px; text-align: center; width: 100%"
                    >
                      <tr>
                        <td
                          style="
                            padding-left: 3px;
                            padding-right: 3px;
                            text-align: center;
                          "
                        >
                          <div
                            style="
                              padding-left: 25px;

                              text-align: center;
                            "
                          >
                            <barcode
                              :display-value="false"
                              :value="HorseUserNumber"
                              height="50px"
                              margin="0px"
                            >
                              Some thing went wrong.
                            </barcode>
                          </div>
                          <div style="text-align: center; padding-right: 20px">
                            <span
                              >*
                              {{
                                HorseUserNumber.toString()
                                  .split("")
                                  .toString()
                                  .replaceAll(",", " &nbsp;&nbsp; ")
                              }}
                              *</span
                            >
                          </div>
                        </td>
                      </tr>
                    </table>

                    <div
                      v-if="commonFeatures.length > 0"
                      style="text-align: center"
                    >
                      <span
                        style="text-align: center; font-weight: bold"
                        v-if="commonFeatures.length > 0"
                      >
                        {{ commonFeatures[0].phone }}

                        {{ commonFeatures[0].others }}

                        {{ commonFeatures[0].brand_name }}
                      </span>
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" class="text-capitalize" text>
                      Waiting...
                    </v-btn>
                    <v-btn
                      class="text-capitalize"
                      dark
                      color="red"
                      text
                      @click="HorsePrintDialog = false"
                    >
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog
                v-model="HorseIsPlaying"
                persistent
                max-width="420"
                class="red"
                :retain-focus="false"
              >
                <p
                  class="headline white--text pink pa-5"
                  style="border-radius: 5px"
                >
                  <v-btn
                    outlined
                    rounded
                    x-small
                    @click="HorseVerifyDialog = true"
                  >
                    <span class="text-capitalize white--text">Verify</span>
                  </v-btn>

                  <v-btn
                    x-small
                    text
                    @click="whichToShow = 'dog'"
                    class="ml-10"
                  >
                    <span class="text-capitalize">
                      <v-img
                        contain
                        height="20px"
                        width="40px"
                        src="../assets/commonimages/PlatinumHoundsIcon.svg"
                      ></v-img>
                    </span>
                  </v-btn>

                  <v-btn x-small text @click="whichToShow = 'car'">
                    <span class="text-capitalize">
                      <v-img
                        contain
                        height="20px"
                        width="40px"
                        src="../assets/commonimages/MotorRacingIcon.svg"
                      ></v-img>
                    </span>
                  </v-btn>

                  <br />
                  Horses are in running mode, please try later! <br />
                  <!-- <v-btn small text @click="whichToShow = 'Horse'">
                    <span
                      class="mr-2 text-capitalize white--text text--darken-1"
                      >Switch to Horse</span
                    >
                  </v-btn> -->
                </p>
              </v-dialog>

              <v-dialog
                v-model="HorseVerifyDialog"
                persistent
                max-width="420"
                :retain-focus="false"
              >
                <v-card class="text-center pt-5">
                  <v-card-title> Verify user </v-card-title>
                  <v-form @submit.prevent="">
                    <v-card-text>
                      <P
                        >Scan the Bar code or Enter user Id under the Bar
                        code</P
                      >
                      <v-text-field
                        autofocus
                        style="font-size: 11px"
                        v-model="HorseUserIDToVerify"
                        :rules="[(v) => !!v || 'Required']"
                        label="User ID or Bar code"
                        required
                        dense
                        outlined
                        prepend-icon="view_column"
                      ></v-text-field>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        :loading="HorseVerifyLoading"
                        color="green darken-1"
                        class="text-capitalize"
                        text
                        type="submit"
                        @click="horseVerify"
                      >
                        verify
                      </v-btn>
                      <v-btn
                        class="text-capitalize"
                        dark
                        color="red"
                        text
                        @click="
                          HorseVerifyDialog = false;
                          HorseUserIDToVerify = '';
                        "
                      >
                        Cancel
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>

              <v-dialog
                v-model="HorseReportTicketDialog"
                persistent
                max-width="420"
              >
                <v-card class="text-center pt-5">
                  <v-card-title> Report ticket </v-card-title>
                  <v-form @submit.prevent="">
                    <v-card-text class="pl-5 pr-5">
                      <P
                        >Scan the Bar code or Enter user Id under the Bar
                        code</P
                      >
                      <v-text-field
                        autofocus
                        style="font-size: 11px"
                        v-model="HorseUserIdForReport"
                        :rules="[(v) => !!v || 'Required']"
                        label="User ID or Bar code"
                        required
                        dense
                        outlined
                        hide-details="true"
                        prepend-icon="view_column"
                      ></v-text-field>

                      <v-text-field
                        class="mt-3"
                        hide-details="true"
                        style="font-size: 11px"
                        v-model="HorseReportReason"
                        :rules="[(v) => !!v || 'Required']"
                        label="Write a reason"
                        required
                        dense
                        outlined
                        prepend-icon="note"
                      ></v-text-field>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        :loading="HorseReportLoading"
                        color="green darken-1"
                        class="text-capitalize"
                        text
                        type="submit"
                        @click="horseReportTicket"
                      >
                        report
                      </v-btn>
                      <v-btn
                        class="text-capitalize"
                        dark
                        color="red"
                        text
                        @click="
                          HorseReportTicketDialog = false;
                          HorseUserIdForReport = '';
                        "
                      >
                        Cancel
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>

              <v-dialog
                v-model="HorseWinnersDialog"
                persistent
                max-width="420"
                :retain-focus="false"
              >
                <v-card class="text-center pt-5">
                  <v-card-title> Check ticket </v-card-title>

                  <v-card-text>
                    <P
                      class="green--text"
                      v-if="HorseTotalWinMoneyForOneUser > 0"
                    >
                      <v-icon class="green--text">check</v-icon>
                      User won total
                      <strong
                        v-if="
                          HorseVerifyUserData[0].getJackpotHistory.length > 0
                        "
                        >{{
                          (
                            HorseTotalWinMoneyForOneUser +
                            HorseVerifyUserData[0].getJackpotHistory[0].amount
                          ).toFixed(2)
                        }}</strong
                      >

                      <strong v-else>{{
                        HorseTotalWinMoneyForOneUser.toFixed(2)
                      }}</strong>
                      Birr <br /><br />
                      <p
                        v-if="
                          HorseVerifyUserData[0].getJackpotHistory.length > 0
                        "
                        class="green--text"
                      >
                        {{
                          HorseVerifyUserData[0].getJackpotHistory[0].jack_type
                        }}:
                        {{
                          HorseVerifyUserData[0].getJackpotHistory[0].amount.toFixed(
                            2
                          )
                        }}
                        Birr Won
                      </p>

                      <div v-for="(wonList, i) in payableList" :key="i">
                        <span
                          class="red--text"
                          v-if="wonList.includes(' Loss ')"
                        >
                          {{ wonList }}
                        </span>

                        <span v-else>
                          {{ wonList }}
                        </span>
                      </div>
                    </P>
                    <P class="red--text" v-else
                      ><v-icon class="red--text">cancel</v-icon> User loss the
                      game!
                    </P>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      :loading="HorsePayLoading"
                      color="green darken-1"
                      class="text-capitalize"
                      text
                      type="submit"
                      v-if="HorseTotalWinMoneyForOneUser > 0"
                      @click="horsePayWinnerAward"
                    >
                      Pay
                    </v-btn>
                    <v-btn
                      class="text-capitalize"
                      dark
                      color="red"
                      text
                      @click="HorseWinnersDialog = false"
                    >
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog
                v-model="HorseCancelDialog"
                persistent
                max-width="420"
                top
              >
                <v-card class="text-center pt-5">
                  <v-card-title> Cancel Ticket </v-card-title>

                  <v-form @submit.prevent="">
                    <v-card-text>
                      <P
                        >Scan the Bar code or Enter user Id under the Bar to
                        cancel the ticket</P
                      >
                      <v-text-field
                        autofocus
                        style="font-size: 11px"
                        v-model="HorseUserIDToCancel"
                        :rules="[(v) => !!v || 'Required']"
                        label="User ID or Bar code"
                        required
                        dense
                        outlined
                        prepend-icon="view_column"
                      ></v-text-field>

                      <P>Are you sure you want to cancel this ticket?</P>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        :loading="HorseCancelLoading"
                        color="green darken-1"
                        class="text-capitalize"
                        text
                        type="submit"
                        @click="horseCancelTicket"
                      >
                        Yes
                      </v-btn>
                      <v-btn
                        class="text-capitalize"
                        dark
                        color="red"
                        text
                        @click="HorseCancelDialog = false"
                      >
                        No
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>

              <v-dialog
                v-model="horseComboDialog"
                persistent
                max-width="300"
                v-if="HorseCurrentGame.length > 0 && horseCombo != ''"
              >
                <v-card class="text-center pt-1">
                  <v-card-title>
                    Combinations<v-spacer>
                      <v-btn
                        class="text-capitalize"
                        dark
                        color="red"
                        text
                        @click="
                          horseComboDialog = false;
                          horseCombo = '';
                        "
                      >
                        Cancel
                      </v-btn></v-spacer
                    >
                  </v-card-title>

                  <v-card-text>
                    <h3 class="text-center">{{ horseCombo }}</h3>

                    <v-layout row wrap>
                      <v-flex
                        xs4
                        class="pa-1"
                        v-for="(combo, i) in HorseCurrentGame[0].howmany_dogs"
                        :key="i + 100"
                      >
                        <a
                          @click="horseCombo = horseCombo + '-' + (i + 1)"
                          v-if="
                            horseCombo.split('-').indexOf((i + 1).toString()) ==
                            -1
                          "
                        >
                          <v-card class="text-center pt-4 pb-4">
                            <span>{{ i + 1 }}</span>
                          </v-card>
                        </a>
                      </v-flex>
                    </v-layout>
                    <div class="mt-2">
                      <v-layout row wrap>
                        <v-flex
                          v-if="horseCombo.split('-').length == 2"
                          xs4
                          class="pa-1"
                        >
                          <a
                            @click="
                              selectedHorseNumbers.push(
                                'Exacta*' +
                                  horseCombo +
                                  '*' +
                                  (
                                    comboHorseOdd.exacta *
                                    parseFloat(
                                      HorseCurrentGame[0].odd_multiplier.split(
                                        ','
                                      )[parseInt(horseCombo.split('-')[0]) - 1]
                                    )
                                  ).toFixed(0)
                              );
                              horseCurrentMousePosition =
                                selectedHorseNumbers.length - 1;
                              inWhichToAddAmount = 'number';
                              horseComboDialog = false;
                              horseCombo = '';
                            "
                          >
                            <v-card class="text-center pt-4 pb-4">
                              <span>Exacta </span> <br />
                              <span style="font-size: 11px">2 in order </span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex
                          v-if="horseCombo.split('-').length == 2"
                          xs4
                          class="pa-1"
                        >
                          <a
                            @click="
                              selectedHorseNumbers.push(
                                'Quinella*' +
                                  horseCombo +
                                  '*' +
                                  (
                                    comboHorseOdd.quinella *
                                    parseFloat(
                                      HorseCurrentGame[0].odd_multiplier.split(
                                        ','
                                      )[parseInt(horseCombo.split('-')[0]) - 1]
                                    )
                                  ).toFixed(0)
                              );
                              horseCurrentMousePosition =
                                selectedHorseNumbers.length - 1;
                              inWhichToAddAmount = 'number';
                              horseComboDialog = false;
                              horseCombo = '';
                            "
                          >
                            <v-card class="text-center pt-4 pb-4">
                              <span>Quinella</span><br />
                              <span style="font-size: 11px">2 any order </span>
                            </v-card>
                          </a>
                        </v-flex>

                        <v-flex
                          v-if="horseCombo.split('-').length == 2"
                          xs4
                          class="pa-1"
                        >
                          <a
                            @click="
                              selectedHorseNumbers.push(
                                'Swinger*' +
                                  horseCombo +
                                  '*' +
                                  (
                                    comboHorseOdd.swinger *
                                    parseFloat(
                                      HorseCurrentGame[0].odd_multiplier.split(
                                        ','
                                      )[parseInt(horseCombo.split('-')[0]) - 1]
                                    )
                                  ).toFixed(0)
                              );
                              horseCurrentMousePosition =
                                selectedHorseNumbers.length - 1;
                              inWhichToAddAmount = 'number';
                              horseComboDialog = false;
                              horseCombo = '';
                            "
                          >
                            <v-card class="text-center pt-4 pb-4">
                              <span>Swinger</span>
                              <br />
                              <span style="font-size: 11px">2 in three </span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex
                          v-if="horseCombo.split('-').length == 3"
                          xs4
                          class="pa-1"
                        >
                          <a
                            @click="
                              selectedHorseNumbers.push(
                                'Trifecta*' +
                                  horseCombo +
                                  '*' +
                                  (
                                    comboHorseOdd.trifecta *
                                    parseFloat(
                                      HorseCurrentGame[0].odd_multiplier.split(
                                        ','
                                      )[parseInt(horseCombo.split('-')[0]) - 1]
                                    )
                                  ).toFixed(0)
                              );
                              horseCurrentMousePosition =
                                selectedHorseNumbers.length - 1;
                              inWhichToAddAmount = 'number';
                              horseComboDialog = false;
                              horseCombo = '';
                            "
                          >
                            <v-card class="text-center pt-4 pb-4">
                              <span>Trifecta</span><br />
                              <span style="font-size: 11px">3 in order </span>
                            </v-card>
                          </a>
                        </v-flex>

                        <v-flex
                          v-if="horseCombo.split('-').length == 3"
                          xs4
                          class="pa-1"
                        >
                          <a
                            @click="
                              selectedHorseNumbers.push(
                                'Trio*' +
                                  horseCombo +
                                  '*' +
                                  (
                                    comboHorseOdd.trio *
                                    parseFloat(
                                      HorseCurrentGame[0].odd_multiplier.split(
                                        ','
                                      )[parseInt(horseCombo.split('-')[0]) - 1]
                                    )
                                  ).toFixed(0)
                              );
                              horseCurrentMousePosition =
                                selectedHorseNumbers.length - 1;
                              inWhichToAddAmount = 'number';
                              horseComboDialog = false;
                              horseCombo = '';
                            "
                          >
                            <v-card class="text-center pt-4 pb-4">
                              <span>Trio</span><br />
                              <span style="font-size: 11px">3 any order </span>
                            </v-card>
                          </a>
                        </v-flex>
                      </v-layout>
                    </div>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </div>
            <v-footer app class="d-flex flex-column">
              <div class="" style="text-align: cener">
                <v-btn
                  small
                  style="background-color: grey"
                  text
                  @click="whichToShow = 'dog'"
                  v-if="whichToShow == 'dog'"
                >
                  <span class="text-capitalize">
                    <v-img
                      contain
                      height="25px"
                      width="50px"
                      src="../assets/commonimages/PlatinumHoundsIcon.svg"
                    ></v-img>
                  </span>
                </v-btn>
                <v-btn small text @click="whichToShow = 'dog'" v-else>
                  <span class="text-capitalize">
                    <v-img
                      contain
                      height="25px"
                      width="50px"
                      src="../assets/commonimages/PlatinumHoundsIcon.svg"
                    ></v-img>
                  </span>
                </v-btn>
                <v-btn
                  small
                  text
                  @click="whichToShow = 'horse'"
                  v-if="whichToShow == 'horse'"
                  style="background-color: grey"
                >
                  <span class="text-capitalize">
                    <v-img
                      contain
                      height="25px"
                      width="50px"
                      src="../assets/commonimages/DashingDerbyIcon.svg"
                    ></v-img>
                  </span>
                </v-btn>
                <v-btn small text @click="whichToShow = 'horse'" v-else>
                  <span class="text-capitalize">
                    <v-img
                      contain
                      height="25px"
                      width="50px"
                      src="../assets/commonimages/DashingDerbyIcon.svg"
                    ></v-img>
                  </span>
                </v-btn>

                <v-btn
                  small
                  text
                  @click="whichToShow = 'car'"
                  v-if="whichToShow == 'car'"
                  style="background-color: grey"
                >
                  <span class="text-capitalize">
                    <v-img
                      contain
                      height="25px"
                      width="50px"
                      src="../assets/commonimages/MotorRacingIcon.svg"
                    ></v-img>
                  </span>
                </v-btn>
                <v-btn small text @click="whichToShow = 'car'" v-else>
                  <span class="text-capitalize">
                    <v-img
                      contain
                      height="25px"
                      width="50px"
                      src="../assets/commonimages/MotorRacingIcon.svg"
                    ></v-img>
                  </span>
                </v-btn>
              </div>
            </v-footer>
            <v-dialog v-model="logoutDialog" persistent max-width="420">
              <v-card class="pt-5">
                <v-form @submit.prevent="">
                  <v-card-text
                    ref="printReport"
                    style="font-size: 12px; font-family: Arial"
                  >
                    <div>
                      <div
                        style="
                          clear: right;
                          float: right;
                          font-size: 12px;
                          text-align: right;
                        "
                      >
                        <br />
                        <span>
                          {{ this.partnerBalance.shop_number }}
                        </span>
                        <br />
                        <span>
                          {{ this.$store.state.cashier.cashier_full_name }}
                        </span>
                      </div>

                      <span style="font-weight: bold; margin-left: 0px">
                        <!-- {{ commonFeatures[0].brand_name }} K E N O -->
                        <img
                          height="40"
                          width="60"
                          v-if="partnerBalance.logo != 'Normal'"
                          :src="require(`../assets/${partnerBalance.logo}.png`)"
                        />
                      </span>
                    </div>
                    <br />

                    <span>
                      <strong> Log in time:</strong> <br />
                      {{
                        new Date(Number($store.state.cashier.loggin_time))
                          | formatDate
                      }}
                    </span>
                    <br />
                    <span>
                      <strong>Log out time:</strong><br />
                      {{ new Date(Number(Date.now())) | formatDate }} </span
                    ><br />
                    <span>
                      <strong>Location:</strong> <br />
                      {{ currentLocation }}</span
                    ><br />
                    <span>
                      <strong>IP Address:</strong> <br />
                      {{ ipAddress }}</span
                    >
                    <br />
                    <hr style="margin-top: 2px; margin-bottom: 2px" />

                    <table style="width: 100%; font-size: 12px">
                      <tr>
                        <td>
                          <strong>Total Tickets:</strong>
                        </td>
                        <td style="text-align: center">
                          | &nbsp; {{ noTickets + HorseNoTickets }}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>Paid Tickets:</strong>
                        </td>
                        <td style="text-align: center">
                          | &nbsp; {{ noPaidTickets + HorseNoPaidTickets }}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>Cancelled Tickets:</strong>
                        </td>
                        <td style="text-align: center">
                          | &nbsp;
                          {{ noCancelledTickets + HorseNoCancelledTickets }}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>Number of Bets:</strong>
                        </td>
                        <td style="text-align: center">
                          | &nbsp; {{ noBets + HorseNoBets }}
                        </td>
                      </tr>
                      <tr>
                        <td style="margin-top: 2px; margin-bottom: 2px">
                          <hr />
                        </td>
                        <td style="margin-top: 2px; margin-bottom: 2px">
                          <hr />
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>Total money:</strong>
                        </td>
                        <td style="text-align: center">
                          | &nbsp; <span style="font-size: 14px">Br </span>
                          {{
                            (totalMoneyOnHand + HorseTotalMoneyOnHand).toFixed(
                              2
                            )
                          }}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>Total paid money:</strong>
                        </td>
                        <td style="text-align: center">
                          | &nbsp; <span style="font-size: 14px">Br </span>
                          {{
                            (
                              totalMoneyPaid +
                              HorseTotalMoneyPaid +
                              paidDogMoneyFromYesterday +
                              paidHorseMoneyFromYesterday
                            ).toFixed(2)
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Total paid from yesterday:</strong>
                        </td>
                        <td style="text-align: center">
                          | &nbsp; <span style="font-size: 14px">Br </span>
                          {{
                            (
                              paidDogMoneyFromYesterday +
                              paidHorseMoneyFromYesterday
                            ).toFixed(2)
                          }}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>Total money on hand:</strong>
                        </td>
                        <td style="text-align: center">
                          | &nbsp; <span style="font-size: 14px">Br </span>
                          {{
                            (
                              totalMoneyOnHand -
                              totalMoneyPaid -
                              paidDogMoneyFromYesterday +
                              (HorseTotalMoneyOnHand -
                                HorseTotalMoneyPaid -
                                paidHorseMoneyFromYesterday)
                            ).toFixed(2)
                          }}
                        </td>
                      </tr>
                    </table>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      :loading="logoutLoading"
                      class="text-capitalize pink--text"
                      text
                      @click="logout"
                    >
                      print report
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
            <v-dialog v-model="tmhPopUp" persistent max-width="420">
              <v-card class="pt-5">
                <v-card-title> Total Money on Hand </v-card-title>
                <v-form @submit.prevent="">
                  <v-card-text>
                    <P style="font-size: 18px"
                      >Total money:
                      {{
                        (totalMoneyOnHand + HorseTotalMoneyOnHand).toFixed(2)
                      }}
                      <span style="font-size: 14px">ETB</span></P
                    >

                    <P style="font-size: 18px"
                      >Total paid money:
                      {{
                        (
                          totalMoneyPaid +
                          HorseTotalMoneyPaid +
                          paidDogMoneyFromYesterday +
                          paidHorseMoneyFromYesterday
                        ).toFixed(2)
                      }}
                      <span style="font-size: 14px">ETB</span></P
                    >

                    <P style="font-size: 18px"
                      >Total money on hand:
                      {{
                        (
                          totalMoneyOnHand -
                          totalMoneyPaid -
                          paidDogMoneyFromYesterday +
                          (HorseTotalMoneyOnHand -
                            HorseTotalMoneyPaid -
                            paidHorseMoneyFromYesterday)
                        ).toFixed(2)
                      }}
                      <span style="font-size: 14px">ETB</span></P
                    >
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      class="text-capitalize"
                      dark
                      color="red"
                      text
                      @click="tmhPopUp = false"
                    >
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>

            <!-- <v-dialog v-model="unpaidDialog" persistent max-width="600">
              <v-card class="pt-5">
                <v-card-title>
                  Tickets <v-spacer></v-spacer>

                  <v-btn
                    class="text-capitalize"
                    dark
                    color="red"
                    text
                    @click="unpaidDialog = false"
                  >
                    Cancel
                  </v-btn></v-card-title
                >

                <v-card-text>
                  <v-layout row wrap class="text-center">
                    <v-flex
                      xs6
                      class="pa-2"
                      style="border-right: 1px solid grey"
                    >
                      <h3 class="black--text text-center"> ticket#</h3>
                      <br />

                      <v-layout row wrap>
                        <v-flex xs6 class="pa-2">
                          <span class="black--text text-center">
                            <u>Paid = {{ kenoPaidList.length }}</u> </span
                          ><br />
                          <span v-for="keno in kenoPaidList" :key="keno"
                            >#{{ keno }} <br />
                          </span>
                        </v-flex>

                        <v-flex xs6 class="pa-2">
                          <span class="black--text text-center">
                            <u>Unpaid = {{ kenoUnpaidList.length }}</u> </span
                          ><br />
                          <span v-for="keno in kenoUnpaidList" :key="keno"
                            >#{{ keno }} <br />
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs6 class="pa-2">
                      <h3 class="black--text text-center">Horse ticket#</h3>
                      <br />

                      <v-layout row wrap>
                        <v-flex xs6 class="pa-2">
                          <span class="black--text text-center">
                            <u>Paid = {{ HorsePaidList.length }}</u> </span
                          ><br />
                          <span v-for="Horse in HorsePaidList" :key="Horse"
                            >#{{ Horse }} <br />
                          </span>
                        </v-flex>

                        <v-flex xs6 class="pa-2">
                          <span class="black--text text-center">
                            <u>Unpaid = {{ HorseUnpaidList.length }}</u> </span
                          ><br />
                          <span v-for="Horse in HorseUnpaidList" :key="Horse"
                            >#{{ Horse }} <br />
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-dialog> -->
            <v-dialog
              v-model="last20GameDialog"
              persistent
              max-width="700"
              v-if="lastTwentyGames.length > 0"
            >
              <v-card class="">
                <v-card-text
                  class=""
                  ref="lastGamePrintable"
                  style="font-family: Arial"
                >
                  <div>
                    <div
                      style="
                        clear: right;
                        float: right;
                        font-size: 12px;
                        text-align: right;
                      "
                    >
                      <br />
                      <span>
                        {{ this.partnerBalance.shop_number }}
                      </span>
                      <br />
                      <span>
                        {{ this.$store.state.cashier.cashier_full_name }}
                      </span>
                      <br />

                      <span>
                        {{ new Date(Date.now()) | formatDate }}

                        (UTC +3)
                      </span>
                    </div>

                    <span style="font-weight: bold; margin-left: 0px">
                      <!-- {{ commonFeatures[0].brand_name }} K E N O -->
                      <img
                        height="40"
                        width="60"
                        v-if="partnerBalance.logo != 'Normal'"
                        :src="require(`../assets/${partnerBalance.logo}.png`)"
                      />
                    </span>
                  </div>
                  <div>
                    <v-flex xs12> <br /> </v-flex>
                  </div>

                  <div style="text-align: left; font-size: 12px">
                    <div
                      style="
                        text-align: center;
                        margin-bottom: 2px;
                        font-size: 14px;
                      "
                    >
                      <br /><br />

                      <p style="font-weight: bold; font-size: 16px">
                        Last 20 Racing
                      </p>

                      <hr />
                      <span v-if="whichToShow == 'dog'">
                        Game: Dog Racing
                      </span>
                      <span v-if="whichToShow == 'horse'">
                        Game: Horse Racing
                      </span>
                      <span v-if="whichToShow == 'car'">
                        Game: Car Racing
                      </span>

                      <br />
                      Race: ({{ lastTwentyGames[0].game_number }} -
                      {{
                        lastTwentyGames[lastTwentyGames.length - 1].game_number
                      }})
                    </div>
                    <br />
                    <span v-if="whichToShow == 'dog'">
                      <span v-for="(game, i) in lastTwentyGames" :key="i">
                        #{{ game.game_number }}:
                        {{
                          game.drawn_numbers.split("")[0] +
                          game.drawn_numbers.split("")[1] +
                          game.drawn_numbers.split("")[2]
                        }}

                        <span>
                          <span v-if="(i + 1) % 4 != 0">&nbsp;&nbsp;</span>
                        </span>
                      </span>
                    </span>
                    <span v-else>
                      <span v-for="(game, i) in lastTwentyGames" :key="i">
                        #{{ game.game_number }}:
                        {{
                          game.drawn_numbers.split(" ")[0] +
                          game.drawn_numbers.split(" ")[1] +
                          game.drawn_numbers.split(" ")[2]
                        }}

                        <span>
                          <span v-if="(i + 1) % 4 != 0">&nbsp;&nbsp;</span>
                        </span>
                      </span>
                    </span>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="printLastgame"
                    color="green darken-1"
                    class="text-capitalize"
                    text
                  >
                    Print
                  </v-btn>
                  <v-btn
                    class="text-capitalize"
                    dark
                    color="red"
                    text
                    @click="last20GameDialog = false"
                  >
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="helpDialog" persistent fullscreen>
              <v-card class="pt-5">
                <v-card-title>
                  Help <v-spacer></v-spacer>

                  <v-btn
                    class="text-capitalize"
                    dark
                    color="red"
                    text
                    @click="
                      helpDialog = false;
                      wichVideoToPlay = '';
                    "
                  >
                    Cancel
                  </v-btn></v-card-title
                >

                <v-card-text>
                  <v-layout
                    row
                    wrap
                    class="mb-15 pa-1 pt-0"
                    style="font-size: 14px"
                    justify-space-around
                  >
                    <v-flex xs12 class="pt-3">
                      <v-textarea
                        v-model="helpText"
                        :rules="[(v) => !!v || 'Required']"
                        auto-grow
                        outlined
                        rows="4"
                        row-height="15"
                        disabled
                      ></v-textarea>
                      <!-- <v-text-field
                  style="font-size: 11px"
                  v-model="helpText"
                  :rules="[(v) => !!v || 'Required']"
                  label="Write Help"
                  required
                  dense
                  outlined
                  append-icon="search"
                ></v-text-field> -->
                    </v-flex>
                    <v-flex
                      xs12
                      class="text-center"
                      v-for="(allVideo, i) in video"
                      :key="i"
                    >
                      <video
                        v-if="allVideo != '' && wichVideoToPlay == allVideo"
                        playsinline
                        style="pointer-events: none"
                        id="video"
                        width="100%"
                        autoplay
                        :src="allVideo"
                      ></video>
                      <a
                        @click="wichVideoToPlay = allVideo"
                        class="mr-3"
                        v-if="allVideo != ''"
                      >
                        Play Video {{ i }}</a
                      >
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-dialog>
          </div>
          <div class="hidden-lg-only text-center white--text">
            <p>Not supported in small screens, please open in pc</p>
          </div>
        </div>
      </div>
      <div v-else class="mt-16">
        <p v-if="partnerBalance != ''" class="headline red--text text-center">
          Reachs maximum day, Please contact the admin
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import VueBarcode from "vue-barcode";

import axios from "axios";

export default {
  components: {
    barcode: VueBarcode,
  },
  data() {
    return {
      isLoad: false,
      helpDialog: false,
      helpText: "",
      help: [],
      wichVideoToPlay: "",
      video: "",
      timePercent: 0,
      timeInSeconds: 0,
      minutes: 9,
      seconds: "00",
      selectedNumbers: [],
      selectedNumberOptionCounter: 0,
      userIDToVerify: "",
      userIDToCancel: "",
      isDuplicated: false,
      printDialog: false,
      verifyDialog: false,
      verifyLoading: false,
      winnersDialog: false,
      cancelDialog: false,
      payLoading: false,
      cancelLoading: false,
      reportTicketDialog: false,
      userIdForReport: "",
      reportReason: "",
      gameDate: "",
      userNumber: "",
      reportLoading: false,
      currentGame: [],
      noTickets: 0,
      noPaidTickets: 0,
      noCancelledTickets: 0,
      noBets: 0,
      comboDogOdd: "",
      comboDog: "",
      comboDialog: false,

      comboHorseOdd: "",
      horseCombo: "",
      horseComboDialog: false,
      lastTickeloading: false,
      dogs: [
        {
          image: "1.png",
          win: 24.59,
          place: 3.4,
          exacta: 110,
          quinella: 86,
          swinger: 45,
          trifecta: 345,
          trio: 203,
        },
        {
          image: "2.png",
          win: 24.59,
          place: 3.4,
          exacta: 110,
          quinella: 86,
          swinger: 45,
          trifecta: 345,
          trio: 203,
        },
        {
          image: "3.png",
          win: 24.59,
          place: 3.4,
          exacta: 110,
          quinella: 86,
          swinger: 45,
          trifecta: 345,
          trio: 203,
        },
        {
          image: "4.png",
          win: 24.59,
          place: 3.4,
          exacta: 110,
          quinella: 86,
          swinger: 45,
          trifecta: 345,
          trio: 203,
        },
        {
          image: "5.png",
          win: 24.59,
          place: 3.4,
          exacta: 110,
          quinella: 86,
          swinger: 45,
          trifecta: 345,
          trio: 203,
        },

        {
          image: "6.png",
          win: 24.59,
          place: 3.4,
          exacta: 110,
          quinella: 86,
          swinger: 45,
          trifecta: 345,
          trio: 203,
        },
        {
          image: "7.png",
          win: 24.59,
          place: 3.4,
          exacta: 110,
          quinella: 86,
          swinger: 45,
          trifecta: 345,
          trio: 203,
        },
        {
          image: "8.png",
          win: 24.59,
          place: 3.4,
          exacta: 110,
          quinella: 86,
          swinger: 45,
          trifecta: 345,
          trio: 203,
        },
      ],

      // exacta: 110,
      //     quinella: 86,
      //     swinger: 45,
      //     trifecta: 345,
      //     trio: 203,
      // horse: [
      //   "silk_01.png",

      //   "silk_02.png",

      //   "silk_03.png",

      //   "silk_05.png",

      //   "silk_06.png",

      //   "silk_07.png",

      //   "silk_09.png",

      //   "silk_11.png",

      //   "silk_12.png",

      //   "silk_13.png",

      //   "silk_14.png",

      //   "silk_15.png",

      //   "silk_16.png",

      //   "silk_17.png",
      //   "silk_18.png",
      //   "silk_19.png",
      //   "silk_20.png",
      //   "silk_21.png",
      //   "silk_22.png",
      //   "silk_24.png",
      //   "silk_26.png",
      //   "silk_27.png",
      //   "silk_28.png",
      //   "silk_29.png",
      //   "silk_30.png",
      //   "silk_31.png",
      //   "silk_32.png",
      //   "silk_33.png",
      //   "silk_34.png",
      //   "silk_37.png",
      //   "silk_40.png",
      // ],
      verifyUserData: [],
      selectedEvenOddAndHighLow: [],
      isPlaying: false,
      totalWinMoneyForOneUser: 0,
      evenOddInputMoney: [],
      numberInputMoney: [],
      totalMoneyOnHand: 0,
      totalMoneyPaid: 0,
      selectedCustomAmount: 0,
      totalInputMoney: 0,
      totalPossibleWin: 0,
      lastTwoGames: [],

      //Horse
      neighboursPopUp: false,
      twoNumbersPopUp: false,
      HorseTimePercent: 0,
      HorseTimeInSeconds: 0,
      HorseMinutes: 9,
      HorseSeconds: "00",
      selectedHorseNumbers: [],
      HorseUserIDToVerify: "",
      HorseUserIDToCancel: "",
      HorseIsDuplicated: false,
      HorsePrintDialog: false,
      HorseVerifyDialog: false,
      HorseVerifyLoading: false,
      HorseWinnersDialog: false,
      HorseCancelDialog: false,
      HorsePayLoading: false,
      HorseCancelLoading: false,
      HorseReportTicketDialog: false,
      HorseUserIdForReport: "",
      HorseReportReason: "",
      HorseGameDate: "",
      HorseUserNumber: "",
      HorseReportLoading: false,
      HorseCurrentGame: [],
      HorseNoTickets: 0,
      HorseNoPaidTickets: 0,
      HorseNoCancelledTickets: 0,
      HorseNoBets: 0,
      HorseVerifyUserData: [],
      HorseIsPlaying: false,
      HorseTotalWinMoneyForOneUser: 0,
      HorseInputMoney: [],
      HorseTotalMoneyOnHand: 0,
      HorseTotalMoneyPaid: 0,
      HorseLastTwoGames: [],
      HorseNumbers: [],

      horseSelectedCustomAmount: 0,

      HorseTotalInputMoney: 0,
      HorseTotalPossibleWin: 0,
      twoNumbers: [],
      //common
      partnerBalance: "",
      logoutLoading: false,
      logoutDialog: false,
      commonFeatures: [],
      tmhPopUp: false,
      customAmount: [250, 200, 150, 100, 50, 40, 30, 25, 20, 15, 10],
      currentLocation: "",
      ipAddress: "",
      rules: [(value) => !!value || "Required."],
      whichToShow: "dog",
      payableList: [],
      paidHorseMoneyFromYesterday: 0,
      paidDogMoneyFromYesterday: 0,
      unpaidLoading: false,
      kenoUnpaidList: [],
      HorseUnpaidList: [],

      kenoPaidList: [],
      HorsePaidList: [],
      todaysWork: [],
      HorseTodaysWork: [],
      unpaidDialog: false,
      dogCurrentMousePosition: "",
      horseCurrentMousePosition: "",
      kenoevenoddCurrentMousePosition: "",
      inWhichToAddAmount: "",
      day_block: "",
      gameLoading: false,
      last20GameDialog: false,
      lastTwentyGames: [],
    };
  },

  methods: {
    getCombo(dog) {
      this.comboDogOdd = dog;
      this.comboDog = this.comboDogOdd.image.split(".")[0].trim();
      this.comboDialog = true;
    },
    getHorseCombo(dog) {
      this.comboHorseOdd = {
        win: 24.59,
        place: 3.4,
        exacta: 110,
        quinella: 86,
        swinger: 45,
        trifecta: 345,
        trio: 203,
      };
      this.horseCombo = dog.toString();

      this.horseComboDialog = true;
    },
    assignSelectedAmount(amount) {
      this.selectedCustomAmount = amount;
      if (
        this.dogCurrentMousePosition >= 0 &&
        this.inWhichToAddAmount == "number"
      )
        this.numberInputMoney[this.dogCurrentMousePosition] =
          this.selectedCustomAmount;
      if (
        this.kenoevenoddCurrentMousePosition >= 0 &&
        this.inWhichToAddAmount == "evenodd"
      )
        this.evenOddInputMoney[this.kenoevenoddCurrentMousePosition] =
          this.selectedCustomAmount;
    },
    horseAssignSelectedAmount(amount) {
      this.horseSelectedCustomAmount = amount;
      if (
        this.horseCurrentMousePosition >= 0 &&
        this.inWhichToAddAmount == "number"
      )
        this.HorseInputMoney[this.horseCurrentMousePosition] =
          this.horseSelectedCustomAmount;
    },

    applyToAll() {
      this.numberInputMoney = [];
      this.evenOddInputMoney = [];
      for (let i = 0; i < this.selectedNumbers.length; i++) {
        this.numberInputMoney.push(this.selectedCustomAmount);
      }

      for (let i = 0; i < this.selectedEvenOddAndHighLow.length; i++) {
        this.evenOddInputMoney.push(this.selectedCustomAmount);
      }
    },
    horseApplyToAll() {
      this.HorseInputMoney = [];
      this.evenOddInputMoney = [];
      for (let i = 0; i < this.selectedHorseNumbers.length; i++) {
        this.HorseInputMoney.push(this.horseSelectedCustomAmount);
      }

      // for (let i = 0; i < this.selectedEvenOddAndHighLow.length; i++) {
      //   this.evenOddInputMoney.push(this.selectedCustomAmount);
      // }
    },
    clearAll() {
      this.numberInputMoney = [];
      this.evenOddInputMoney = [];
      this.selectedCustomAmount = 0;
    },
    horseClearAll() {
      this.HorseInputMoney = [];
      this.evenOddInputMoney = [];
      this.horseSelectedCustomAmount = 0;
    },
    selectNumber(number) {
      if (this.whichToShow == "dog") {
        this.selectedNumbers.push(number);
        this.dogCurrentMousePosition = this.selectedNumbers.length - 1;
        this.inWhichToAddAmount = "number";
      } else if (this.whichToShow == "horse") {
        this.selectedHorseNumbers.push(number);
        this.horseCurrentMousePosition = this.selectedHorseNumbers.length - 1;
        this.inWhichToAddAmount = "number";
      }
    },

    // selectNumber(number) {
    //   this.selectedNumbers.push(number);
    //   this.dogCurrentMousePosition = this.selectedNumbers.length - 1;
    //   this.inWhichToAddAmount = "number";
    // },

    removeEvenOddKenoList(item) {
      let index = this.selectedEvenOddAndHighLow.indexOf(item);
      this.selectedEvenOddAndHighLow.splice(index, 1);
      this.evenOddInputMoney.splice(index, 1);
      this.kenoevenoddCurrentMousePosition =
        this.selectedEvenOddAndHighLow.length - 1;
    },
    removeSelectedNumbers(index) {
      this.selectedNumbers.splice(index, 1);
      this.numberInputMoney.splice(index, 1);
      if (this.selectedNumberOptionCounter > 0)
        this.selectedNumberOptionCounter--;
      this.dogCurrentMousePosition = this.selectedNumbers.length - 1;
    },

    removeSelectedHorseNumbers(index) {
      this.selectedHorseNumbers.splice(index, 1);
      this.HorseInputMoney.splice(index, 1);
      if (this.selectedNumberOptionCounter > 0)
        this.selectedNumberOptionCounter--;
      this.horseCurrentMousePosition = this.selectedHorseNumbers.length - 1;
    },

    async getHelp() {
      try {
        var helpResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
              getCashierHelp {
                      help_tbl_id
                      help_text
                      video
                    
                    }
              
              }
              `,
          },
        });

        this.help = helpResult.data.data.getCashierHelp;
        this.helpText = this.help[0].help_text;
        this.video = this.help[0].video.split(",");
      } catch (err) {
        alert(err);
      }
    },

    async getUnpaid() {
      this.unpaidLoading = true;

      var date2 = Date.now();
      var date1 =
        new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995;
      try {
        var todayResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
               getDailyWorksByCashierId(date1:"${date1}", date2:"${date2}",   cashier_id:${this.$store.state.cashier.cashier_id}){
                      ticket_id
                      game_id
                      user_id
                      choosen_numbers
                      others
                      ticket_date
                      winner_award
                      is_cancelled
                      ticket_number
                      getGameForShifts{
                       game_id
                       game_number
                       drawn_numbers
                       game_date
                       high_low
                       even_odd
                     }

                   }
              }
              `,
          },
        });
        this.todaysWork = todayResult.data.data.getDailyWorksByCashierId;

        var rowOut2 = this.$store.state.odds;
        this.kenoUnpaidList = [];
        this.kenoPaidList = [];

        for (let t = 0; t < this.todaysWork.length; t++) {
          var winnersTicketCounter = 0;
          var unpaidWinnersMoney = 0;
          if (this.todaysWork[t].is_cancelled == 0) {
            var tickets = this.todaysWork[t].choosen_numbers.split(":");
            var others = this.todaysWork[t].others.split(":");
            var drownNumbers =
              this.todaysWork[t].getGameForShifts[0].drawn_numbers != ""
                ? this.todaysWork[t].getGameForShifts[0].drawn_numbers.split(
                    ","
                  )
                : [];
            var evenOdd = this.todaysWork[t].getGameForShifts[0].even_odd;
            var highLow = this.todaysWork[t].getGameForShifts[0].high_low;

            if (tickets[0] != "") {
              for (let j = 0; j < tickets.length; j++) {
                var eachNumber = tickets[j].split("*")[0].split(",");
                var eachAmount = parseFloat(tickets[j].split("*")[1]);

                var matchCount = 0;
                for (let k = 0; k < eachNumber.length; k++) {
                  for (let m = 0; m < drownNumbers.length; m++) {
                    if (drownNumbers[m] == eachNumber[k].trim()) {
                      matchCount++;
                    }
                  }
                }

                for (let x = 0; x < rowOut2.length; x++) {
                  if (
                    parseInt(rowOut2[x].choosen_length) == eachNumber.length
                  ) {
                    var hits = rowOut2[x].hits.split(",");
                    var possibleWinMoney = rowOut2[x].odd_value.split(",");

                    for (let y = 0; y < hits.length; y++) {
                      if (matchCount == parseInt(hits[y].trim())) {
                        winnersTicketCounter++;
                        unpaidWinnersMoney =
                          unpaidWinnersMoney +
                          (parseFloat(possibleWinMoney[y].trim()) / 10) *
                            eachAmount;
                      }
                    }
                  }
                }
              }
            }

            if (others[0] != "") {
              for (let r = 0; r < others.length; r++) {
                if (
                  evenOdd.toLowerCase() ==
                  others[r].split("*")[0].trim().toLowerCase()
                ) {
                  for (let x = 0; x < rowOut2.length; x++) {
                    if (
                      rowOut2[x].choosen_length.toLowerCase() ==
                      evenOdd.toLowerCase()
                    ) {
                      winnersTicketCounter++;
                      unpaidWinnersMoney =
                        unpaidWinnersMoney +
                        parseFloat(rowOut2[x].odd_value) *
                          parseFloat(others[r].split("*")[1].trim());
                    }
                  }
                }

                if (
                  highLow.toLowerCase() ==
                  others[r].split("*")[0].trim().toLowerCase()
                ) {
                  for (let x = 0; x < rowOut2.length; x++) {
                    if (
                      rowOut2[x].choosen_length.toLowerCase() ==
                      highLow.toLowerCase()
                    ) {
                      winnersTicketCounter++;
                      unpaidWinnersMoney =
                        unpaidWinnersMoney +
                        parseFloat(rowOut2[x].odd_value) *
                          parseFloat(others[r].split("*")[1].trim());
                    }
                  }
                }
              }
            }
          }
          if (
            winnersTicketCounter != 0 &&
            this.todaysWork[t].winner_award == 0
          ) {
            this.kenoUnpaidList.push(
              this.todaysWork[t].ticket_number +
                " = " +
                unpaidWinnersMoney +
                " Birr"
            );
          }

          if (
            winnersTicketCounter != 0 &&
            this.todaysWork[t].winner_award != 0
          ) {
            this.kenoPaidList.push(this.todaysWork[t].ticket_number);
          }
        }
      } catch (err) {
        alert(err);
      }

      var date2 = Date.now();
      var date1 =
        new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995;

      // try {
      //   var todayResult = await axios({
      //     method: "POST",
      //     url: this.$store.state.horseCashierURL,

      //     data: {
      //       query: `{
      //         getDailyWorksByCashierId(date1:"${date1}", date2:"${date2}",   cashier_id:${this.$store.state.cashier.cashier_id}){
      //                 ticket_id
      //                 game_id
      //                 user_id
      //                 choosen_numbers
      //                 others
      //                 ticket_date
      //                 winner_award
      //                 is_cancelled
      //                 ticket_number
      //                 getGameForShifts{
      //                  game_id
      //                  game_number
      //                  drawn_numbers
      //                  game_date
      //                  high_low
      //                  even_odd
      //                   getHorseNumber{
      //                       Horse_number_id
      //                       number
      //                       color
      //                       mirror
      //                       twins
      //                       final
      //                       dozen
      //                       sector
      //                       combination
      //                       even_odd
      //                       high_low
      //                       neighbors
      //                     }
      //                }

      //              }
      //         }
      //         `,
      //     },
      //   });
      //   this.HorseTodaysWork = todayResult.data.data.getDailyWorksByCashierId;

      //   var rowOut2 = this.$store.state.HorseOdds;

      //   this.HorseUnpaidList = [];
      //   this.HorsePaidList = [];

      //   for (let t = 0; t < this.HorseTodaysWork.length; t++) {
      //     var winnersTicketCounter = 0;
      //     var unpaidWinnersMoney = 0;
      //     if (this.HorseTodaysWork[t].is_cancelled == 0) {
      //       var tickets = this.HorseTodaysWork[t].choosen_numbers.split(":");
      //       var HorseNum =
      //         this.HorseTodaysWork[t].getGameForShifts[0].getHorseNumber;

      //       if (tickets[0] != "") {
      //         this.HorseNoBets = this.HorseNoBets + tickets.length;
      //         for (let j = 0; j < tickets.length; j++) {
      //           var eachNumber = tickets[j].split("*")[0].trim();
      //           var eachAmount = parseFloat(tickets[j].split("*")[1]);

      //           if (HorseNum.length > 0) {
      //             if (HorseNum[0].number.toString() == eachNumber) {
      //               winnersTicketCounter++;

      //               unpaidWinnersMoney =
      //                 unpaidWinnersMoney + rowOut2[0].number * eachAmount;
      //             } else if (eachNumber.includes(",")) {
      //               if (
      //                 eachNumber.split(",")[0].trim() ==
      //                   HorseNum[0].number.toString() ||
      //                 eachNumber.split(",")[1].trim() ==
      //                   HorseNum[0].number.toString()
      //               ) {
      //                 winnersTicketCounter++;

      //                 unpaidWinnersMoney =
      //                   unpaidWinnersMoney +
      //                   (rowOut2[0].number / 2) * eachAmount;
      //               }
      //             } else if (HorseNum[0].color == eachNumber) {
      //               if (eachNumber == "0 Green") {
      //                 winnersTicketCounter++;

      //                 unpaidWinnersMoney =
      //                   unpaidWinnersMoney + rowOut2[0].number * eachAmount;
      //               } else {
      //                 winnersTicketCounter++;

      //                 unpaidWinnersMoney =
      //                   unpaidWinnersMoney + rowOut2[0].high_low * eachAmount;
      //               }
      //             } else if (HorseNum[0].sector == eachNumber) {
      //               winnersTicketCounter++;

      //               unpaidWinnersMoney =
      //                 unpaidWinnersMoney + rowOut2[0].sector * eachAmount;
      //             } else if (HorseNum[0].even_odd == eachNumber) {
      //               winnersTicketCounter++;

      //               unpaidWinnersMoney =
      //                 unpaidWinnersMoney + rowOut2[0].even_odd * eachAmount;
      //             } else if (HorseNum[0].high_low == eachNumber) {
      //               winnersTicketCounter++;

      //               unpaidWinnersMoney =
      //                 unpaidWinnersMoney + rowOut2[0].high_low * eachAmount;
      //             } else if (
      //               HorseNum[0].neighbors.split(",")[0].trim() == eachNumber ||
      //               HorseNum[0].neighbors.split(",")[1].trim() == eachNumber ||
      //               HorseNum[0].neighbors.split(",")[2].trim() == eachNumber ||
      //               HorseNum[0].neighbors.split(",")[3].trim() == eachNumber ||
      //               HorseNum[0].neighbors.split(",")[4].trim() == eachNumber
      //             ) {
      //               winnersTicketCounter++;

      //               unpaidWinnersMoney =
      //                 unpaidWinnersMoney + rowOut2[0].neighbors * eachAmount;
      //             } else if (HorseNum[0].twins == eachNumber) {
      //               winnersTicketCounter++;

      //               unpaidWinnersMoney =
      //                 unpaidWinnersMoney + rowOut2[0].twins * eachAmount;
      //             } else if (HorseNum[0].dozen == eachNumber) {
      //               winnersTicketCounter++;

      //               unpaidWinnersMoney =
      //                 unpaidWinnersMoney + rowOut2[0].dozen * eachAmount;
      //             } else if (eachNumber.includes("Final")) {
      //               if (HorseNum[0].final.includes(eachNumber)) {
      //                 winnersTicketCounter++;

      //                 unpaidWinnersMoney =
      //                   unpaidWinnersMoney + rowOut2[0].final * eachAmount;
      //               }
      //             } else if (HorseNum[0].mirror == eachNumber) {
      //               winnersTicketCounter++;

      //               unpaidWinnersMoney =
      //                 unpaidWinnersMoney + rowOut2[0].mirror * eachAmount;
      //             } else if (HorseNum[0].combination == eachNumber) {
      //               winnersTicketCounter++;

      //               unpaidWinnersMoney =
      //                 unpaidWinnersMoney +
      //                 rowOut2[0].high_low_color * eachAmount;
      //             }
      //           }
      //         }
      //       }
      //     }
      //     if (
      //       winnersTicketCounter != 0 &&
      //       this.HorseTodaysWork[t].winner_award == 0
      //     )
      //       this.HorseUnpaidList.push(
      //         this.HorseTodaysWork[t].ticket_number +
      //           " = " +
      //           unpaidWinnersMoney +
      //           " Birr"
      //       );

      //     if (
      //       winnersTicketCounter != 0 &&
      //       this.HorseTodaysWork[t].winner_award != 0
      //     )
      //       this.HorsePaidList.push(this.HorseTodaysWork[t].ticket_number);
      //   }
      // } catch (err) {}
      this.unpaidDialog = true;
      this.unpaidLoading = false;
    },

    async getLastTwentyGames(game) {
      this.lastTwentyGames = [];
      this.gameLoading = true;

      if (game == "dog") {
        try {
          var gameResult = await axios({
            method: "POST",
            url: this.$store.state.cashierURL,

            data: {
              query: `{
                getLastTwentyGames{
                  game_number
                  game_id
                  drawn_numbers
                }
  
                }
                `,
            },
          });

          this.lastTwentyGames = gameResult.data.data.getLastTwentyGames;
        } catch (err) {}
      } else if (game == "horse") {
        try {
          var gameResult = await axios({
            method: "POST",
            url: this.$store.state.horseCashierURL,
            data: {
              query: `{
                getLastTwentyGames{
                  game_number
                  game_id
                  drawn_numbers
                }
                }
                `,
            },
          });
          this.lastTwentyGames = gameResult.data.data.getLastTwentyGames;
        } catch (err) {}
      }
      if (this.lastTwentyGames.length > 0) this.last20GameDialog = true;

      this.gameLoading = false;
    },

    async printLastgame() {
      var content = this.$refs.lastGamePrintable;
      this.last20GameDialog = false;

      var newWindow = window.open("");

      newWindow.document.write(content.outerHTML);
      setTimeout(function () {
        newWindow.print();
        newWindow.close();
      }, 100);
    },

    async recietOverview(id) {
      let count = 0;
      let totalMoney = 0;
      this.totalPossibleWin = 0;
      if (this.selectedNumbers.length == this.numberInputMoney.length) {
        for (let v = 0; v < this.numberInputMoney.length; v++) {
          if (this.numberInputMoney[v] < 10 || this.numberInputMoney[v] > 2000)
            count++;
        }

        for (let i = 0; i < this.selectedNumbers.length; i++) {
          if (this.selectedNumbers[i].length == 0) count++;
          if (this.numberInputMoney[i] == "") count++;
        }
        if (count == 0) {
          this.userNumber = Date.now();
          this.gameDate = Date.now();
          var choosenNumbers = "";
          var othersSelected = "";
          if (
            this.formatDate(Date.now()) !=
            this.$store.state.kenoTicketNumberDate
          ) {
            this.$store.dispatch(
              "kenoTicketNumberDate",
              this.formatDate(Date.now())
            );

            this.$store.dispatch("kenoTicketNumber", 0);
          }
          this.$store.dispatch(
            "kenoTicketNumber",
            this.$store.state.kenoTicketNumber + 1
          );
          for (let i = 0; i < this.selectedNumbers.length; i++) {
            totalMoney = totalMoney + parseFloat(this.numberInputMoney[i]);

            this.totalPossibleWin =
              this.totalPossibleWin +
              parseFloat(this.numberInputMoney[i]) *
                parseFloat(this.selectedNumbers[i].split("*")[2].trim());

            if (choosenNumbers == "") {
              choosenNumbers =
                this.selectedNumbers[i].toString() +
                "*" +
                this.numberInputMoney[i].toString();
            } else
              choosenNumbers =
                choosenNumbers +
                ":" +
                this.selectedNumbers[i].toString() +
                "*" +
                this.numberInputMoney[i].toString();
          }

          if (this.partnerBalance.tempo_package >= totalMoney) {
            this.totalInputMoney = totalMoney;
            this.printDialog = true;

            try {
              var ticketResult = await axios({
                method: "POST",
                url: this.$store.state.cashierURL,

                data: {
                  query: `mutation{
               saveTicket(
                game_id:${this.currentGame[0].game_id},
                ticket_number:${this.$store.state.kenoTicketNumber},
               user_id:"${this.userNumber}",
               cashier_id:${this.$store.state.cashier.cashier_id},
               partner_id:${this.$store.state.cashier.partner_id},
               choosen_numbers: "${choosenNumbers}",
               others: "${othersSelected}",
               ticket_date: "${this.gameDate}",
               replaced_user_id: "${id}",
               tempo_package:${this.partnerBalance.tempo_package - totalMoney}

               )
                {
                      statusMessage

                   }
              }
              `,
                },
              });
              if (
                ticketResult.data.data.saveTicket[0].statusMessage.toString() ==
                "true"
              ) {
                var content = this.$refs.printable;

                var newWindow = window.open("");

                newWindow.document.write(content.outerHTML);
                setTimeout(function () {
                  newWindow.print();
                  newWindow.close();
                }, 140);
                this.printDialog = false;

                await this.getBalance();
                await this.getLastTwoGames();
                this.selectedNumbers = [];

                this.numberInputMoney = [];
              } else alert("Ticket not printed, try again");
            } catch (err) {
              alert(err);
            }
          } else alert("Insufficient funds, please deposit to continue");
        } else
          alert(
            "Please fill all fields, amounts, And make sure the amount is between 10 to 2000 Birr"
          );
      } else
        alert(
          "Please fill all fields, amounts, And make sure the amount is between 10 to 1000"
        );
    },
    async horseRecietOverview(id) {
      let count = 0;
      let totalMoney = 0;
      this.HorseTotalPossibleWin = 0;
      if (this.selectedHorseNumbers.length == this.HorseInputMoney.length) {
        for (let v = 0; v < this.HorseInputMoney.length; v++) {
          if (this.HorseInputMoney[v] < 10 || this.HorseInputMoney[v] > 2000)
            count++;
        }

        for (let i = 0; i < this.selectedHorseNumbers.length; i++) {
          if (this.selectedHorseNumbers[i].length == 0) count++;
          if (this.HorseInputMoney[i] == "") count++;
        }
        if (count == 0) {
          this.HorseUserNumber = Date.now();
          this.HorseGameDate = Date.now();
          var choosenNumbers = "";
          var othersSelected = "";
          if (
            this.formatDate(Date.now()) !=
            this.$store.state.HorseTicketNumberDate
          ) {
            this.$store.dispatch(
              "HorseTicketNumberDate",
              this.formatDate(Date.now())
            );

            this.$store.dispatch("HorseTicketNumber", 0);
          }
          this.$store.dispatch(
            "HorseTicketNumber",
            this.$store.state.HorseTicketNumber + 1
          );
          for (let i = 0; i < this.selectedHorseNumbers.length; i++) {
            totalMoney = totalMoney + parseFloat(this.HorseInputMoney[i]);

            this.HorseTotalPossibleWin =
              this.HorseTotalPossibleWin +
              parseFloat(this.HorseInputMoney[i]) *
                parseFloat(this.selectedHorseNumbers[i].split("*")[2].trim());

            if (choosenNumbers == "") {
              choosenNumbers =
                this.selectedHorseNumbers[i].toString() +
                "*" +
                this.HorseInputMoney[i].toString();
            } else
              choosenNumbers =
                choosenNumbers +
                ":" +
                this.selectedHorseNumbers[i].toString() +
                "*" +
                this.HorseInputMoney[i].toString();
          }

          if (this.partnerBalance.tempo_package >= totalMoney) {
            this.HorseTotalInputMoney = totalMoney;
            this.HorsePrintDialog = true;

            try {
              var ticketResult = await axios({
                method: "POST",
                url: this.$store.state.horseCashierURL,

                data: {
                  query: `mutation{
               saveTicket(
                game_id:${this.HorseCurrentGame[0].game_id},
                ticket_number:${this.$store.state.HorseTicketNumber},
               user_id:"${this.HorseUserNumber}",
               cashier_id:${this.$store.state.cashier.cashier_id},
               partner_id:${this.$store.state.cashier.partner_id},
               choosen_numbers: "${choosenNumbers}",
               others: "${othersSelected}",
               ticket_date: "${this.HorseGameDate}",
               replaced_user_id: "${id}",
               tempo_package:${this.partnerBalance.tempo_package - totalMoney}

               )
                {
                      statusMessage

                   }
              }
              `,
                },
              });
              if (
                ticketResult.data.data.saveTicket[0].statusMessage.toString() ==
                "true"
              ) {
                await axios({
                  method: "POST",
                  url: this.$store.state.cashierURL,

                  data: {
                    query: `mutation{
             updatePackageAfterTicket(             
             tempo_package:${this.partnerBalance.tempo_package - totalMoney}

             )
              {
                    statusMessage

                 }
            }
            `,
                  },
                });

                var content = this.$refs.horsePrintable;

                var newWindow = window.open("");

                newWindow.document.write(content.outerHTML);
                setTimeout(function () {
                  newWindow.print();
                  newWindow.close();
                }, 140);
                this.HorsePrintDialog = false;

                await this.getBalance();
                await this.getLastTwoHorseGames();
                this.selectedHorseNumbers = [];

                this.HorseInputMoney = [];
              } else alert("Ticket not printed, try again");
            } catch (err) {
              alert(err);
            }
          } else alert("Insufficient funds, please deposit to continue");
        } else
          alert(
            "Please fill all fields, amounts, And make sure the amount is between 10 to 2000 Birr"
          );
      } else
        alert(
          "Please fill all fields, amounts, And make sure the amount is between 10 to 1000"
        );
    },
    async getCurrentGame() {
      try {
        var currentGameResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
               getPendingOrPlayingGame(cashier_id:${this.$store.state.cashier.cashier_id},partner_id:${this.$store.state.cashier.partner_id}){
                      game_id
                      game_number
                      drawn_numbers
                      game_date
                      high_low
		                  even_odd
                      game_status
                      howmany_dogs
                      

                      odd_multiplier
                      even_odd_multi
                      winplace_odd_multiplier

                   }
              }
              `,
          },
        });
        this.currentGame = currentGameResult.data.data.getPendingOrPlayingGame;

        if (this.currentGame.length > 0) {
          this.timeInSeconds = Math.floor(
            540 - (Date.now() - Number(this.currentGame[0].game_date)) / 1000
          );

          this.timePercent =
            0.1852 *
            Math.floor(
              (Date.now() - Number(this.currentGame[0].game_date)) / 1000
            );
        } else {
          this.timePercent = 0;
          this.minutes = "00";
          this.seconds = "00";
        }
      } catch (err) {}
    },

    async getCurrentHorseGame() {
      try {
        var currentGameResult = await axios({
          method: "POST",
          url: this.$store.state.horseCashierURL,

          data: {
            query: `{
               getPendingOrPlayingGame(cashier_id:${this.$store.state.cashier.cashier_id},partner_id:${this.$store.state.cashier.partner_id}){
                      game_id
                      game_number
                      drawn_numbers
                      game_date
                      high_low
		                  even_odd
                      game_status
                      howmany_dogs
                      img

                      odd_multiplier
                      even_odd_multi
                      winplace_odd_multiplier

                   }
              }
              `,
          },
        });
        this.HorseCurrentGame =
          currentGameResult.data.data.getPendingOrPlayingGame;

        if (this.HorseCurrentGame.length > 0) {
          this.HorseTimeInSeconds = Math.floor(
            540 -
              (Date.now() - Number(this.HorseCurrentGame[0].game_date)) / 1000
          );

          this.HorseTimePercent =
            0.1852 *
            Math.floor(
              (Date.now() - Number(this.HorseCurrentGame[0].game_date)) / 1000
            );
        } else {
          this.HorseTimePercent = 0;
          this.HorseMinutes = "00";
          this.HorseSeconds = "00";
        }
      } catch (err) {}
    },

    async getLastTwoGames() {
      try {
        var lastGameResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
                  getLastTwoGames(cashier_id:${this.$store.state.cashier.cashier_id}){
                       game_id

                       game_number
                       drawn_numbers
                       game_date
                       high_low
                       even_odd
                       game_status
                       getTickets{
                         cashier_id
                         ticket_id
                         game_id
                         user_id
                         choosen_numbers
                         others
                         ticket_date
                         winner_award
                         is_cancelled
                       }
                     }


              }
              `,
          },
        });

        this.lastTwoGames = lastGameResult.data.data.getLastTwoGames;
        if (this.lastTwoGames.length > 0) {
          var lastGame = this.lastTwoGames[0].getTickets;
          this.isDuplicated = false;
          for (let j = 0; j < lastGame.length; j++) {
            var ticket = lastGame[j];

            if (j != 0) {
              if (lastGame.length != j + 1) {
                if (
                  (lastGame[j - 1].choosen_numbers == ticket.choosen_numbers &&
                    lastGame[j - 1].others == ticket.others &&
                    lastGame[j - 1].cashier_id == ticket.cashier_id) ||
                  (lastGame[j + 1].choosen_numbers == ticket.choosen_numbers &&
                    lastGame[j + 1].others == ticket.others &&
                    lastGame[j + 1].cashier_id == ticket.cashier_id)
                ) {
                  this.isDuplicated = true;
                }
              } else {
                if (
                  lastGame[j - 1].choosen_numbers == ticket.choosen_numbers &&
                  lastGame[j - 1].others == ticket.others &&
                  lastGame[j - 1].cashier_id == ticket.cashier_id
                ) {
                  this.isDuplicated = true;
                }
              }
            } else {
              if (lastGame.length != j + 1) {
                if (
                  lastGame[j + 1].choosen_numbers == ticket.choosen_numbers &&
                  lastGame[j + 1].others == ticket.others &&
                  lastGame[j + 1].cashier_id == ticket.cashier_id
                ) {
                  this.isDuplicated = true;
                }
              }
            }
          }
        }
      } catch (err) {}
    },
    async getLastTwoHorseGames() {
      try {
        var lastGameResult = await axios({
          method: "POST",
          url: this.$store.state.horseCashierURL,

          data: {
            query: `{
                  getLastTwoGames(cashier_id:${this.$store.state.cashier.cashier_id}){
                       game_id

                       game_number
                       drawn_numbers
                       game_date
                       high_low
                       even_odd
                       game_status
                       getTickets{
                         cashier_id
                         ticket_id
                         game_id
                         user_id
                         choosen_numbers
                         others
                         ticket_date
                         winner_award
                         is_cancelled
                       }
                     }


              }
              `,
          },
        });

        this.HorseLastTwoGames = lastGameResult.data.data.getLastTwoGames;
        if (this.HorseLastTwoGames.length > 0) {
          var lastGame = this.HorseLastTwoGames[0].getTickets;
          this.HorseIsDuplicated = false;
          for (let j = 0; j < lastGame.length; j++) {
            var ticket = lastGame[j];

            if (j != 0) {
              if (lastGame.length != j + 1) {
                if (
                  (lastGame[j - 1].choosen_numbers == ticket.choosen_numbers &&
                    lastGame[j - 1].others == ticket.others &&
                    lastGame[j - 1].cashier_id == ticket.cashier_id) ||
                  (lastGame[j + 1].choosen_numbers == ticket.choosen_numbers &&
                    lastGame[j + 1].others == ticket.others &&
                    lastGame[j + 1].cashier_id == ticket.cashier_id)
                ) {
                  this.HorseIsDuplicated = true;
                }
              } else {
                if (
                  lastGame[j - 1].choosen_numbers == ticket.choosen_numbers &&
                  lastGame[j - 1].others == ticket.others &&
                  lastGame[j - 1].cashier_id == ticket.cashier_id
                ) {
                  this.HorseIsDuplicated = true;
                }
              }
            } else {
              if (lastGame.length != j + 1) {
                if (
                  lastGame[j + 1].choosen_numbers == ticket.choosen_numbers &&
                  lastGame[j + 1].others == ticket.others &&
                  lastGame[j + 1].cashier_id == ticket.cashier_id
                ) {
                  this.HorseIsDuplicated = true;
                }
              }
            }
          }
        }
      } catch (err) {}
    },

    async getCommonFeatures() {
      try {
        var commonResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
               getCommonFeatures{
                 common_features_id
                 brand_name
                 phone
                 others
                }


              }
              `,
          },
        });

        this.commonFeatures = commonResult.data.data.getCommonFeatures;
      } catch (err) {
        alert(err);
      }
    },

    async reportTicket() {
      if (this.userIdForReport != "" && this.reportReason != "") {
        this.reportLoading = true;
        try {
          var reportResult = await axios({
            method: "POST",
            url: this.$store.state.cashierURL,

            data: {
              query: `mutation{
               reportTicket(user_id:"${this.userIdForReport}",
              reason: "${this.reportReason}"){
                      statusMessage

                   }
              }
              `,
            },
          });
          if (
            reportResult.data.data.reportTicket[0].statusMessage.toString() ==
            "true"
          ) {
            alert("Reported successfully!");
            this.reportTicketDialog = false;
          } else alert("Some thing went wrong");
        } catch (err) {
          alert(err);
        }
        this.reportLoading = false;
      } else alert("Please fill the fields");
    },
    async horseReportTicket() {
      if (this.HorseUserIdForReport != "" && this.HorseReportReason != "") {
        this.HorseReportLoading = true;
        try {
          var reportResult = await axios({
            method: "POST",
            url: this.$store.state.horseCashierURL,

            data: {
              query: `mutation{
               reportTicket(user_id:"${this.HorseUserIdForReport}",
              reason: "${this.HorseReportReason}"){
                      statusMessage

                   }
              }
              `,
            },
          });
          if (
            reportResult.data.data.reportTicket[0].statusMessage.toString() ==
            "true"
          ) {
            alert("Reported successfully!");
            this.HorseReportTicketDialog = false;
          } else alert("Some thing went wrong");
        } catch (err) {
          alert(err);
        }
        this.HorseReportLoading = false;
      } else alert("Please fill the fields");
    },

    async getBlockedCashier() {
      try {
        var cashierResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
                getBlockedCashier(cashier_id:${this.$store.state.cashier.cashier_id}){
                  is_active
                }

              }
              `,
          },
        });

        if (!cashierResult.data.data.getBlockedCashier[0].is_active) {
          await this.logout();
          alert("Cashier is blocked");
        }
      } catch (err) {}
    },

    async payWinnerAward() {
      this.payLoading = true;
      try {
        var payResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `mutation{
               payWinnerAward(ticket_id:${this.verifyUserData[0].ticket_id},
               payer_cashier_id: ${this.$store.state.cashier.cashier_id},
               user_id:"${this.verifyUserData[0].user_id}",
                winner_award: ${this.totalWinMoneyForOneUser}){
                      statusMessage

                   }
              }
              `,
          },
        });

        if (
          payResult.data.data.payWinnerAward[0].statusMessage.toString() ==
          "true"
        ) {
          if (this.verifyUserData[0].getJackpotHistory.length > 0) {
            await axios({
              method: "POST",
              url: this.$store.state.cashierURL,

              data: {
                query: `mutation{
              editJackpotHistory(jackpot_history_id:${this.verifyUserData[0].getJackpotHistory[0].jackpot_history_id},
              cashier_id: ${this.$store.state.cashier.cashier_id}
           ){
                      statusMessage

                   }
              }
              `,
              },
            });
          }

          if (
            Number(this.verifyUserData[0].ticket_date) <
            new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995
          ) {
            await axios({
              method: "POST",
              url: this.$store.state.cashierURL,

              data: {
                query: `mutation{
                   addPaidFromYesterday(cashier_id:${this.$store.state.cashier.cashier_id},
                   user_id:"${this.verifyUserData[0].user_id}",
                    winner_award: ${this.totalWinMoneyForOneUser}){
                          statusMessage
    
                       }
                  }
                  `,
              },
            });
          }
          this.winnersDialog = false;
          this.verifyDialog = true;
          await this.getBalance();
          this.userIDToVerify = "";
        }
      } catch (err) {
        alert(err);
      }

      this.payLoading = false;
    },

    async horsePayWinnerAward() {
      this.HorsePayLoading = true;
      try {
        var payResult = await axios({
          method: "POST",
          url: this.$store.state.horseCashierURL,

          data: {
            query: `mutation{
               payWinnerAward(ticket_id:${this.HorseVerifyUserData[0].ticket_id},
               payer_cashier_id: ${this.$store.state.cashier.cashier_id},
               user_id:"${this.HorseVerifyUserData[0].user_id}",
                winner_award: ${this.HorseTotalWinMoneyForOneUser}){
                      statusMessage

                   }
              }
              `,
          },
        });

        if (
          payResult.data.data.payWinnerAward[0].statusMessage.toString() ==
          "true"
        ) {
          await axios({
            method: "POST",
            url: this.$store.state.cashierURL,

            data: {
              query: `mutation{
             updatePackageAfterTicket(             
             tempo_package:${
               this.partnerBalance.tempo_package +
               this.HorseTotalWinMoneyForOneUser
             }

             )
              {
                    statusMessage

                 }
            }
            `,
            },
          });
          if (this.HorseVerifyUserData[0].getJackpotHistory.length > 0) {
            await axios({
              method: "POST",
              url: this.$store.state.horseCashierURL,

              data: {
                query: `mutation{
              editJackpotHistory(jackpot_history_id:${this.HorseVerifyUserData[0].getJackpotHistory[0].jackpot_history_id},
              cashier_id: ${this.$store.state.cashier.cashier_id}
           ){
                      statusMessage

                   }
              }
              `,
              },
            });
          }

          if (
            Number(this.HorseVerifyUserData[0].ticket_date) <
            new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995
          ) {
            await axios({
              method: "POST",
              url: this.$store.state.horseCashierURL,

              data: {
                query: `mutation{
                   addPaidFromYesterday(cashier_id:${this.$store.state.cashier.cashier_id},
                   user_id:"${this.HorseVerifyUserData[0].user_id}",
                    winner_award: ${this.HorseTotalWinMoneyForOneUser}){
                          statusMessage
    
                       }
                  }
                  `,
              },
            });
          }
          this.HorseWinnersDialog = false;
          this.HorseVerifyDialog = true;
          await this.getBalance();
          this.HorseUserIDToVerify = "";
          this.HorsePayLoading = false;
        }
      } catch (err) {
        alert(err);
      }

      this.HorsePayLoading = false;
    },

    async printAgain(ticket) {
      var numbers = ticket.choosen_numbers.split(":");
      var others = ticket.others.split(":");
      if (numbers[0] != "") {
        for (let i = 0; i < numbers.length; i++) {
          this.selectedNumbers.push(
            numbers[i].split("*")[0] +
              "*" +
              numbers[i].split("*")[1] +
              "*" +
              numbers[i].split("*")[2]
          );
          this.numberInputMoney.push(numbers[i].split("*")[3].trim());
        }
      }

      if (others[0] != "") {
        for (let j = 0; j < others.length; j++) {
          this.selectedEvenOddAndHighLow.push(others[j].split("*")[0].trim());
          this.evenOddInputMoney.push(others[j].split("*")[1].trim());
        }
      }
      this.userIDToCancel = ticket.user_id;

      await this.recietOverview(ticket.user_id);
      await this.cancelTicket();
    },

    async horsePrintAgain(ticket) {
      var numbers = ticket.choosen_numbers.split(":");
      var others = ticket.others.split(":");
      if (numbers[0] != "") {
        for (let i = 0; i < numbers.length; i++) {
          this.selectedHorseNumbers.push(
            numbers[i].split("*")[0] +
              "*" +
              numbers[i].split("*")[1] +
              "*" +
              numbers[i].split("*")[2]
          );
          this.HorseInputMoney.push(numbers[i].split("*")[3].trim());
        }
      }

      if (others[0] != "") {
        for (let j = 0; j < others.length; j++) {
          this.selectedEvenOddAndHighLow.push(others[j].split("*")[0].trim());
          this.evenOddInputMoney.push(others[j].split("*")[1].trim());
        }
      }
      this.HorseUserIDToCancel = ticket.user_id;

      await this.horseRecietOverview(ticket.user_id);
      await this.horseCancelTicket();
    },

    async printLastGameAgain(ticket) {
      var numbers = ticket.choosen_numbers.split(":");
      var others = ticket.others.split(":");
      if (numbers[0] != "") {
        for (let i = 0; i < numbers.length; i++) {
          this.selectedNumbers.push(
            numbers[i].split("*")[0] +
              "*" +
              numbers[i].split("*")[1] +
              "*" +
              numbers[i].split("*")[2]
          );
          this.numberInputMoney.push(numbers[i].split("*")[3].trim());
        }
      }

      if (others[0] != "") {
        for (let j = 0; j < others.length; j++) {
          this.selectedEvenOddAndHighLow.push(others[j].split("*")[0].trim());
          this.evenOddInputMoney.push(others[j].split("*")[1].trim());
        }
      }

      await this.recietOverview("");
    },

    async printLastHorseGameAgain(ticket) {
      var numbers = ticket.choosen_numbers.split(":");
      var others = ticket.others.split(":");
      if (numbers[0] != "") {
        for (let i = 0; i < numbers.length; i++) {
          this.selectedHorseNumbers.push(
            numbers[i].split("*")[0] +
              "*" +
              numbers[i].split("*")[1] +
              "*" +
              numbers[i].split("*")[2]
          );
          this.HorseInputMoney.push(numbers[i].split("*")[3].trim());
        }
      }

      if (others[0] != "") {
        for (let j = 0; j < others.length; j++) {
          this.selectedEvenOddAndHighLow.push(others[j].split("*")[0].trim());
          this.evenOddInputMoney.push(others[j].split("*")[1].trim());
        }
      }

      await this.horseRecietOverview("");
    },

    async cancelDuplicatedTicket(ticket, lastGame, j) {
      let isDuplicated = false;
      if (j != 0) {
        if (lastGame.length != j + 1) {
          if (
            (lastGame[j - 1].choosen_numbers == ticket.choosen_numbers &&
              lastGame[j - 1].others == ticket.others &&
              lastGame[j - 1].cashier_id == ticket.cashier_id) ||
            (lastGame[j + 1].choosen_numbers == ticket.choosen_numbers &&
              lastGame[j + 1].others == ticket.others &&
              lastGame[j + 1].cashier_id == ticket.cashier_id)
          ) {
            isDuplicated = true;
          }
        } else {
          if (
            lastGame[j - 1].choosen_numbers == ticket.choosen_numbers &&
            lastGame[j - 1].others == ticket.others &&
            lastGame[j - 1].cashier_id == ticket.cashier_id
          ) {
            isDuplicated = true;
          }
        }
      } else {
        if (lastGame.length != j + 1) {
          if (
            lastGame[j + 1].choosen_numbers == ticket.choosen_numbers &&
            lastGame[j + 1].others == ticket.others &&
            lastGame[j + 1].cashier_id == ticket.cashier_id
          ) {
            isDuplicated = true;
          }
        }
      }

      if (isDuplicated) {
        this.userIDToCancel = ticket.user_id;

        await this.cancelTicket();
        await this.getMoneyOnHands();
        this.userIDToCancel = "";
      } else alert("You cannot delete");
    },
    async horseCancelDuplicatedTicket(ticket, lastGame, j) {
      let isDuplicated = false;
      if (j != 0) {
        if (lastGame.length != j + 1) {
          if (
            (lastGame[j - 1].choosen_numbers == ticket.choosen_numbers &&
              lastGame[j - 1].others == ticket.others &&
              lastGame[j - 1].cashier_id == ticket.cashier_id) ||
            (lastGame[j + 1].choosen_numbers == ticket.choosen_numbers &&
              lastGame[j + 1].others == ticket.others &&
              lastGame[j + 1].cashier_id == ticket.cashier_id)
          ) {
            isDuplicated = true;
          }
        } else {
          if (
            lastGame[j - 1].choosen_numbers == ticket.choosen_numbers &&
            lastGame[j - 1].others == ticket.others &&
            lastGame[j - 1].cashier_id == ticket.cashier_id
          ) {
            isDuplicated = true;
          }
        }
      } else {
        if (lastGame.length != j + 1) {
          if (
            lastGame[j + 1].choosen_numbers == ticket.choosen_numbers &&
            lastGame[j + 1].others == ticket.others &&
            lastGame[j + 1].cashier_id == ticket.cashier_id
          ) {
            isDuplicated = true;
          }
        }
      }

      if (isDuplicated) {
        this.HorseUserIDToCancel = ticket.user_id;

        await this.horseCancelTicket();
        await this.getHorseMoneyOnHands();
        this.HorseUserIDToCancel = "";
      } else alert("You cannot delete");
    },
    async cancelTicket() {
      if (this.userIDToCancel != "") {
        this.cancelLoading = true;
        try {
          var cancelResult = await axios({
            method: "POST",
            url: this.$store.state.cashierURL,

            data: {
              query: `mutation{
               cancelTicket(
               user_id:"${this.userIDToCancel}"){
                      statusMessage

                   }
              }
              `,
            },
          });
          if (
            cancelResult.data.data.cancelTicket[0].statusMessage.toString() ==
            "true"
          ) {
            this.cancelDialog = false;
            this.userIDToCancel = "";
            alert("Cancelled succesfully!");
            await this.getBalance();
            await this.getLastTwoGames();
          } else alert("You can not cancel the ticket, or already cancelled");
        } catch (err) {
          alert(err);
        }

        this.cancelLoading = false;
      } else alert("Please scan Bar code or enter user id");
    },

    async horseCancelTicket() {
      if (this.HorseUserIDToCancel != "") {
        this.horseCancelLoading = true;
        try {
          var cancelResult = await axios({
            method: "POST",
            url: this.$store.state.horseCashierURL,

            data: {
              query: `mutation{
               cancelTicket(
                user_id:"${this.HorseUserIDToCancel}",
                tempo_package:${this.partnerBalance.tempo_package},
                cashierURL: "${this.$store.state.cashierURL}", 
               
             ){
                      statusMessage

                   }
              }
              `,
            },
          });
          if (
            cancelResult.data.data.cancelTicket[0].statusMessage.toString() ==
            "true"
          ) {
            this.horseCancelDialog = false;
            this.HorseUserIDToCancel = "";
            alert("Cancelled succesfully!");
            await this.getBalance();
            await this.getLastTwoHorseGames();
          } else alert("You can not cancel the ticket, or already cancelled");
        } catch (err) {
          alert(err);
        }

        this.horseCancelLoading = false;
      } else alert("Please scan Bar code or enter user id");
    },

    async getBalance() {
      try {
        var partnerBalanceResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
                getPartnerById(partner_id:${this.$store.state.cashier.partner_id}){
                     tempo_package
                     permanent_package
                     partner_balance
                     credit_chance
                     shop_number
                     day_block_date
                     day_block
                     ticket_layout
                     logo

                   }

              }
              `,
          },
        });
        this.partnerBalance = partnerBalanceResult.data.data.getPartnerById[0];
        this.day_block = 0;
        var sec = 24 * 60 * 60 * 1000;

        this.day_block = Math.round(
          this.partnerBalance.day_block -
            (Date.now() -
              Number(
                this.partnerBalance.day_block_date != ""
                  ? this.partnerBalance.day_block_date
                  : 0
              )) /
              sec
        );
      } catch (err) {}
    },

    async verify() {
      if (this.userIDToVerify != "") {
        this.verifyLoading = true;
        // await this.getOdds();
        try {
          var verifyResult = await axios({
            method: "POST",
            url: this.$store.state.cashierURL,

            data: {
              query: `{
               verifyUser(user_id:"${this.userIDToVerify}")
                {
                        ticket_id
    game_id
    user_id
    choosen_numbers
    others
    ticket_date
    winner_award
    is_game_over
    is_cancelled   

    getGame{
      game_id
      game_number
      drawn_numbers
      game_date
      high_low
      even_odd
      game_status
      howmany_dogs

    }

    getJackpotHistory{
      jackpot_history_id
      cashier_id
      ticket_id
      selected_no
      amount
      jack_date
      jack_type
      is_paid
    }

                   }
              }
              `,
            },
          });
          this.verifyUserData = verifyResult.data.data.verifyUser;

          var totalWinMoney = 0;
          this.payableList = [];

          if (this.verifyUserData.length > 0) {
            if (
              Number(this.verifyUserData[0].ticket_date) >
              Date.now() - 48 * 60 * 60 * 1000
            ) {
              if (this.verifyUserData[0].is_cancelled == 0) {
                if (this.verifyUserData[0].is_game_over == 1) {
                  if (this.verifyUserData[0].winner_award == 0) {
                    var tickets =
                      this.verifyUserData[0].choosen_numbers.split(":");

                    if (
                      tickets[0] != "" &&
                      this.verifyUserData[0].getGame.length > 0
                    ) {
                      var drownNumbers =
                        this.verifyUserData[0].getGame[0].drawn_numbers;

                      for (let i = 0; i < tickets.length; i++) {
                        let countWin = 0;
                        var eachBet = tickets[i].split("*")[0].trim();

                        var choosenNumber = tickets[i].split("*")[1].trim();

                        var oddValue = parseFloat(
                          tickets[i].split("*")[2].trim()
                        );

                        var eachAmount = parseFloat(
                          tickets[i].split("*")[3].trim()
                        );

                        if (eachBet == "Win") {
                          if (
                            choosenNumber == drownNumbers.split("")[0].trim()
                          ) {
                            totalWinMoney =
                              totalWinMoney + oddValue * eachAmount;

                            countWin++;
                            this.payableList.push(
                              tickets[i] +
                                " = " +
                                (oddValue * eachAmount).toFixed(2) +
                                " Birr Won"
                            );
                          }
                        } else if (eachBet == "Place") {
                          if (
                            this.verifyUserData[0].getGame[0].howmany_dogs == 6
                          ) {
                            if (
                              choosenNumber ==
                                drownNumbers.split("")[0].trim() ||
                              choosenNumber == drownNumbers.split("")[1].trim()
                            ) {
                              totalWinMoney =
                                totalWinMoney + oddValue * eachAmount;

                              countWin++;
                              this.payableList.push(
                                tickets[i] +
                                  " = " +
                                  (oddValue * eachAmount).toFixed(2) +
                                  " Birr Won"
                              );
                            }
                          } else {
                            if (
                              choosenNumber ==
                                drownNumbers.split("")[0].trim() ||
                              choosenNumber ==
                                drownNumbers.split("")[1].trim() ||
                              choosenNumber == drownNumbers.split("")[2].trim()
                            ) {
                              totalWinMoney =
                                totalWinMoney + oddValue * eachAmount;

                              countWin++;
                              this.payableList.push(
                                tickets[i] +
                                  " = " +
                                  (oddValue * eachAmount).toFixed(2) +
                                  " Birr Won"
                              );
                            }
                          }
                        } else if (eachBet == "Exacta") {
                          if (
                            choosenNumber.split("-")[0].trim() ==
                              drownNumbers.split("")[0].trim() &&
                            choosenNumber.split("-")[1].trim() ==
                              drownNumbers.split("")[1].trim()
                          ) {
                            totalWinMoney =
                              totalWinMoney + oddValue * eachAmount;

                            countWin++;
                            this.payableList.push(
                              tickets[i] +
                                " = " +
                                (oddValue * eachAmount).toFixed(2) +
                                " Birr Won"
                            );
                          }
                        } else if (eachBet == "Quinella") {
                          if (
                            choosenNumber.split("-")[0].trim() ==
                              drownNumbers.split("")[0].trim() ||
                            (choosenNumber.split("-")[0].trim() ==
                              drownNumbers.split("")[1].trim() &&
                              choosenNumber.split("-")[1].trim() ==
                                drownNumbers.split("")[0].trim()) ||
                            choosenNumber.split("-")[1].trim() ==
                              drownNumbers.split("")[1].trim()
                          ) {
                            totalWinMoney =
                              totalWinMoney + oddValue * eachAmount;

                            countWin++;
                            this.payableList.push(
                              tickets[i] +
                                " = " +
                                (oddValue * eachAmount).toFixed(2) +
                                " Birr Won"
                            );
                          }
                        } else if (eachBet == "Swinger") {
                          var swingerMatch =
                            drownNumbers.split("")[0].trim() +
                            "," +
                            drownNumbers.split("")[1].trim() +
                            "," +
                            drownNumbers.split("")[2].trim();
                          if (
                            swingerMatch.includes(
                              choosenNumber.split("-")[0].trim()
                            ) &&
                            swingerMatch.includes(
                              choosenNumber.split("-")[1].trim()
                            )
                          ) {
                            totalWinMoney =
                              totalWinMoney + oddValue * eachAmount;

                            countWin++;
                            this.payableList.push(
                              tickets[i] +
                                " = " +
                                (oddValue * eachAmount).toFixed(2) +
                                " Birr Won"
                            );
                          }
                        } else if (eachBet == "Trifecta") {
                          if (
                            choosenNumber.split("-")[0].trim() ==
                              drownNumbers.split("")[0].trim() &&
                            choosenNumber.split("-")[1].trim() ==
                              drownNumbers.split("")[1].trim() &&
                            choosenNumber.split("-")[2].trim() ==
                              drownNumbers.split("")[2].trim()
                          ) {
                            totalWinMoney =
                              totalWinMoney + oddValue * eachAmount;

                            countWin++;
                            this.payableList.push(
                              tickets[i] +
                                " = " +
                                (oddValue * eachAmount).toFixed(2) +
                                " Birr Won"
                            );
                          }
                        } else if (eachBet == "Trio") {
                          var swingerMatch =
                            drownNumbers.split("")[0].trim() +
                            "," +
                            drownNumbers.split("")[1].trim() +
                            "," +
                            drownNumbers.split("")[2].trim();
                          if (
                            swingerMatch.includes(
                              choosenNumber.split("-")[0].trim()
                            ) &&
                            swingerMatch.includes(
                              choosenNumber.split("-")[1].trim()
                            ) &&
                            swingerMatch.includes(
                              choosenNumber.split("-")[2].trim()
                            )
                          ) {
                            totalWinMoney =
                              totalWinMoney + oddValue * eachAmount;

                            countWin++;
                            this.payableList.push(
                              tickets[i] +
                                " = " +
                                (oddValue * eachAmount).toFixed(2) +
                                " Birr Won"
                            );
                          }
                        } else if (
                          eachBet == "High" ||
                          eachBet == "Low" ||
                          eachBet == "Even" ||
                          eachBet == "Odd"
                        ) {
                          if (
                            choosenNumber ==
                              this.verifyUserData[0].getGame[0].high_low ||
                            choosenNumber ==
                              this.verifyUserData[0].getGame[0].even_odd
                          ) {
                            totalWinMoney =
                              totalWinMoney + oddValue * eachAmount;

                            countWin++;
                            this.payableList.push(
                              tickets[i] +
                                " = " +
                                (oddValue * eachAmount).toFixed(2) +
                                " Birr Won"
                            );
                          }
                        }

                        if (countWin == 0)
                          this.payableList.push(tickets[i] + " = Loss ");
                      }
                    }

                    this.totalWinMoneyForOneUser = totalWinMoney;

                    this.verifyDialog = false;
                    this.winnersDialog = true;
                  } else alert("Already paid");
                } else alert("Game is not over");
              } else alert("Ticket cancelled");
            } else alert("Ticket expired");
          } else alert("Ticket is not exist");
        } catch (err) {
          alert(err);
        }
        this.verifyLoading = false;
        this.userIDToVerify = "";
      } else alert("Please scan Bar code or enter user id");
    },

    async horseVerify() {
      if (this.HorseUserIDToVerify != "") {
        this.HorseVerifyLoading = true;
        // await this.getOdds();
        try {
          var verifyResult = await axios({
            method: "POST",
            url: this.$store.state.horseCashierURL,

            data: {
              query: `{
               verifyUser(user_id:"${this.HorseUserIDToVerify}")
                {
                        ticket_id
    game_id
    user_id
    choosen_numbers
    others
    ticket_date
    winner_award
    is_game_over
    is_cancelled   

    getGame{
      game_id
      game_number
      drawn_numbers
      game_date
      high_low
      even_odd
      game_status
      howmany_dogs

    }

    getJackpotHistory{
      jackpot_history_id
      cashier_id
      ticket_id
      selected_no
      amount
      jack_date
      jack_type
      is_paid
    }

                   }
              }
              `,
            },
          });
          this.HorseVerifyUserData = verifyResult.data.data.verifyUser;

          var totalWinMoney = 0;
          this.payableList = [];

          if (this.HorseVerifyUserData.length > 0) {
            if (
              Number(this.HorseVerifyUserData[0].ticket_date) >
              Date.now() - 48 * 60 * 60 * 1000
            ) {
              if (this.HorseVerifyUserData[0].is_cancelled == 0) {
                if (this.HorseVerifyUserData[0].is_game_over == 1) {
                  if (this.HorseVerifyUserData[0].winner_award == 0) {
                    var tickets =
                      this.HorseVerifyUserData[0].choosen_numbers.split(":");

                    if (
                      tickets[0] != "" &&
                      this.HorseVerifyUserData[0].getGame.length > 0
                    ) {
                      var drownNumbers =
                        this.HorseVerifyUserData[0].getGame[0].drawn_numbers;

                      for (let i = 0; i < tickets.length; i++) {
                        let countWin = 0;
                        var eachBet = tickets[i].split("*")[0].trim();

                        var choosenNumber = tickets[i].split("*")[1].trim();

                        var oddValue = parseFloat(
                          tickets[i].split("*")[2].trim()
                        );

                        var eachAmount = parseFloat(
                          tickets[i].split("*")[3].trim()
                        );

                        if (eachBet == "Win") {
                          if (
                            choosenNumber == drownNumbers.split(" ")[0].trim()
                          ) {
                            totalWinMoney =
                              totalWinMoney + oddValue * eachAmount;

                            countWin++;
                            this.payableList.push(
                              tickets[i] +
                                " = " +
                                (oddValue * eachAmount).toFixed(2) +
                                " Birr Won"
                            );
                          }
                        } else if (eachBet == "Place") {
                          if (
                            this.HorseVerifyUserData[0].getGame[0]
                              .howmany_dogs == 6
                          ) {
                            if (
                              choosenNumber ==
                                drownNumbers.split(" ")[0].trim() ||
                              choosenNumber == drownNumbers.split(" ")[1].trim()
                            ) {
                              totalWinMoney =
                                totalWinMoney + oddValue * eachAmount;

                              countWin++;
                              this.payableList.push(
                                tickets[i] +
                                  " = " +
                                  (oddValue * eachAmount).toFixed(2) +
                                  " Birr Won"
                              );
                            }
                          } else {
                            if (
                              choosenNumber ==
                                drownNumbers.split(" ")[0].trim() ||
                              choosenNumber ==
                                drownNumbers.split(" ")[1].trim() ||
                              choosenNumber == drownNumbers.split(" ")[2].trim()
                            ) {
                              totalWinMoney =
                                totalWinMoney + oddValue * eachAmount;

                              countWin++;
                              this.payableList.push(
                                tickets[i] +
                                  " = " +
                                  (oddValue * eachAmount).toFixed(2) +
                                  " Birr Won"
                              );
                            }
                          }
                        } else if (eachBet == "Exacta") {
                          if (
                            choosenNumber.split("-")[0].trim() ==
                              drownNumbers.split(" ")[0].trim() &&
                            choosenNumber.split("-")[1].trim() ==
                              drownNumbers.split(" ")[1].trim()
                          ) {
                            totalWinMoney =
                              totalWinMoney + oddValue * eachAmount;

                            countWin++;
                            this.payableList.push(
                              tickets[i] +
                                " = " +
                                (oddValue * eachAmount).toFixed(2) +
                                " Birr Won"
                            );
                          }
                        } else if (eachBet == "Quinella") {
                          if (
                            choosenNumber.split("-")[0].trim() ==
                              drownNumbers.split(" ")[0].trim() ||
                            (choosenNumber.split("-")[0].trim() ==
                              drownNumbers.split(" ")[1].trim() &&
                              choosenNumber.split("-")[1].trim() ==
                                drownNumbers.split(" ")[0].trim()) ||
                            choosenNumber.split("-")[1].trim() ==
                              drownNumbers.split(" ")[1].trim()
                          ) {
                            totalWinMoney =
                              totalWinMoney + oddValue * eachAmount;

                            countWin++;
                            this.payableList.push(
                              tickets[i] +
                                " = " +
                                (oddValue * eachAmount).toFixed(2) +
                                " Birr Won"
                            );
                          }
                        } else if (eachBet == "Swinger") {
                          var swingerMatch =
                            "," +
                            drownNumbers.split(" ")[0].trim() +
                            "," +
                            drownNumbers.split(" ")[1].trim() +
                            "," +
                            drownNumbers.split(" ")[2].trim() +
                            ",";
                          if (
                            swingerMatch.includes(
                              "," + choosenNumber.split("-")[0].trim() + ","
                            ) &&
                            swingerMatch.includes(
                              "," + choosenNumber.split("-")[1].trim() + ","
                            )
                          ) {
                            totalWinMoney =
                              totalWinMoney + oddValue * eachAmount;

                            countWin++;
                            this.payableList.push(
                              tickets[i] +
                                " = " +
                                (oddValue * eachAmount).toFixed(2) +
                                " Birr Won"
                            );
                          }
                        } else if (eachBet == "Trifecta") {
                          if (
                            choosenNumber.split("-")[0].trim() ==
                              drownNumbers.split(" ")[0].trim() &&
                            choosenNumber.split("-")[1].trim() ==
                              drownNumbers.split(" ")[1].trim() &&
                            choosenNumber.split("-")[2].trim() ==
                              drownNumbers.split(" ")[2].trim()
                          ) {
                            totalWinMoney =
                              totalWinMoney + oddValue * eachAmount;

                            countWin++;
                            this.payableList.push(
                              tickets[i] +
                                " = " +
                                (oddValue * eachAmount).toFixed(2) +
                                " Birr Won"
                            );
                          }
                        } else if (eachBet == "Trio") {
                          var swingerMatch =
                            "," +
                            drownNumbers.split(" ")[0].trim() +
                            "," +
                            drownNumbers.split(" ")[1].trim() +
                            "," +
                            drownNumbers.split(" ")[2].trim() +
                            ",";
                          if (
                            swingerMatch.includes(
                              "," + choosenNumber.split("-")[0].trim() + ","
                            ) &&
                            swingerMatch.includes(
                              "," + choosenNumber.split("-")[1].trim() + ","
                            ) &&
                            swingerMatch.includes(
                              "," + choosenNumber.split("-")[2].trim() + ","
                            )
                          ) {
                            totalWinMoney =
                              totalWinMoney + oddValue * eachAmount;

                            countWin++;
                            this.payableList.push(
                              tickets[i] +
                                " = " +
                                (oddValue * eachAmount).toFixed(2) +
                                " Birr Won"
                            );
                          }
                        } else if (
                          eachBet == "High" ||
                          eachBet == "Low" ||
                          eachBet == "Even" ||
                          eachBet == "Odd"
                        ) {
                          if (
                            choosenNumber ==
                              this.HorseVerifyUserData[0].getGame[0].high_low ||
                            choosenNumber ==
                              this.HorseVerifyUserData[0].getGame[0].even_odd
                          ) {
                            totalWinMoney =
                              totalWinMoney + oddValue * eachAmount;

                            countWin++;
                            this.payableList.push(
                              tickets[i] +
                                " = " +
                                (oddValue * eachAmount).toFixed(2) +
                                " Birr Won"
                            );
                          }
                        }

                        if (countWin == 0)
                          this.payableList.push(tickets[i] + " = Loss ");
                      }
                    }

                    this.HorseTotalWinMoneyForOneUser = totalWinMoney;

                    this.HorseVerifyDialog = false;
                    this.HorseWinnersDialog = true;
                  } else alert("Already paid");
                } else alert("Game is not over");
              } else alert("Ticket cancelled");
            } else alert("Ticket expired");
          } else alert("Ticket is not exist");
        } catch (err) {
          alert(err);
        }
        this.HorseVerifyLoading = false;
        this.HorseUserIDToVerify = "";
      } else alert("Please scan Bar code or enter user id");
    },

    async getLastTicket() {
      if (this.userIDToVerify != "") {
        // await this.getOdds();
        this.lastTickeloading = true;
        try {
          var verifyResult = await axios({
            method: "POST",
            url: this.$store.state.cashierURL,

            data: {
              query: `{
               verifyUser(user_id:"${this.userIDToVerify}")
                {
                        ticket_id
    game_id
    user_id
    choosen_numbers
    others   

                   }
              }
              `,
            },
          });

          if (verifyResult.data.data.verifyUser.length > 0) {
            await this.printLastGameAgain(verifyResult.data.data.verifyUser[0]);
          } else alert("Ticket is not exist");
        } catch (err) {
          alert(err);
        }

        this.userIDToVerify = "";
        this.lastTickeloading = false;
      } else alert("Please scan Bar code or enter user id");
    },
    async getLastHorseTicket() {
      if (this.HorseUserIDToVerify != "") {
        // await this.getOdds();
        this.lastTickeloading = true;
        try {
          var verifyResult = await axios({
            method: "POST",
            url: this.$store.state.horseCashierURL,

            data: {
              query: `{
               verifyUser(user_id:"${this.HorseUserIDToVerify}")
                {
                        ticket_id
    game_id
    user_id
    choosen_numbers
    others   

                   }
              }
              `,
            },
          });

          if (verifyResult.data.data.verifyUser.length > 0) {
            await this.printLastHorseGameAgain(
              verifyResult.data.data.verifyUser[0]
            );
          } else alert("Ticket is not exist");
        } catch (err) {
          alert(err);
        }

        this.userIDToVerify = "";
        this.lastTickeloading = false;
      } else alert("Please scan Bar code or enter user id");
    },
    async getMoneyOnHands() {
      var date2 = "";
      var date1 = "";

      date2 = Date.now();
      date1 =
        new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995;
      var paidFromCashier = 0;
      var paidFromCashierTicket = 0;

      try {
        var paidForOtherCashierResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
               getPaidFromOtherCashier(cashier_id: ${this.$store.state.cashier.cashier_id}, date1:"${date1}", date2:"${date2}"){
                    choosen_numbers
                    cashier_id
                    others
                    winner_award
                    is_cancelled
                    payer_cashier_id

                   }
              }
              `,
          },
        });

        var moneyOnHand =
          paidForOtherCashierResult.data.data.getPaidFromOtherCashier;

        for (let t = 0; t < moneyOnHand.length; t++) {
          paidFromCashier = paidFromCashier + moneyOnHand[t].winner_award;

          paidFromCashierTicket = paidFromCashierTicket + 1;
        }
      } catch (err) {}
      try {
        var moneyOnHandsResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
               getMoneyOnHands(cashier_id: ${this.$store.state.cashier.cashier_id}, date1:"${date1}", date2:"${date2}"){
                    choosen_numbers
                    cashier_id
                    others
                    winner_award
                    is_cancelled
                    payer_cashier_id

                   }
              }
              `,
          },
        });

        var moneyOnHand = moneyOnHandsResult.data.data.getMoneyOnHands;
        this.totalMoneyOnHand = 0;
        this.totalMoneyPaid = 0;
        this.noTickets = 0;
        this.noPaidTickets = 0;
        this.noCancelledTickets = 0;
        this.noBets = 0;

        //for (let i = 0; i < moneyOnHand.length; i++) {
        for (let t = 0; t < moneyOnHand.length; t++) {
          if (moneyOnHand[t].is_cancelled == 0) {
            var tickets = moneyOnHand[t].choosen_numbers.split(":");

            if (tickets[0] != "") {
              this.noBets = this.noBets + tickets.length;
              for (let j = 0; j < tickets.length; j++) {
                this.totalMoneyOnHand =
                  this.totalMoneyOnHand + parseFloat(tickets[j].split("*")[3]);
              }
            }
          }

          if (
            moneyOnHand[t].winner_award != 0 &&
            moneyOnHand[t].cashier_id == moneyOnHand[t].payer_cashier_id
          ) {
            this.totalMoneyPaid =
              this.totalMoneyPaid + moneyOnHand[t].winner_award;

            this.noPaidTickets = this.noPaidTickets + 1;
          }

          if (moneyOnHand[t].is_cancelled == 1) {
            this.noCancelledTickets = this.noCancelledTickets + 1;
          } else this.noTickets = this.noTickets + 1;
        }

        this.totalMoneyPaid = this.totalMoneyPaid + paidFromCashier;

        this.noPaidTickets = this.noPaidTickets + paidFromCashierTicket;
      } catch (err) {}

      try {
        var paidMoneyFromYesterdayResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
               getMoneyFromYesterday(cashier_id: ${this.$store.state.cashier.cashier_id}, date1:"${date1}", date2:"${date2}"){
                    paid_from_yesterday_id
                    cashier_id
                    user_id
                    winner_award
                    paid_date
                   }
              }
              `,
          },
        });

        var paidMoneyFromYesterday =
          paidMoneyFromYesterdayResult.data.data.getMoneyFromYesterday;
        this.paidDogMoneyFromYesterday = 0;
        for (let k = 0; k < paidMoneyFromYesterday.length; k++) {
          this.paidDogMoneyFromYesterday =
            this.paidDogMoneyFromYesterday +
            paidMoneyFromYesterday[k].winner_award;
        }
      } catch (err) {}
    },
    async getHorseMoneyOnHands() {
      var date2 = "";
      var date1 = "";

      date2 = Date.now();
      date1 =
        new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995;
      var paidFromCashier = 0;
      var paidFromCashierTicket = 0;

      try {
        var paidForOtherCashierResult = await axios({
          method: "POST",
          url: this.$store.state.horseCashierURL,

          data: {
            query: `{
               getPaidFromOtherCashier(cashier_id: ${this.$store.state.cashier.cashier_id}, date1:"${date1}", date2:"${date2}"){
                    choosen_numbers
                    cashier_id
                    others
                    winner_award
                    is_cancelled
                    payer_cashier_id

                   }
              }
              `,
          },
        });

        var moneyOnHand =
          paidForOtherCashierResult.data.data.getPaidFromOtherCashier;

        for (let t = 0; t < moneyOnHand.length; t++) {
          paidFromCashier = paidFromCashier + moneyOnHand[t].winner_award;

          paidFromCashierTicket = paidFromCashierTicket + 1;
        }
      } catch (err) {}
      try {
        var moneyOnHandsResult = await axios({
          method: "POST",
          url: this.$store.state.horseCashierURL,

          data: {
            query: `{
               getMoneyOnHands(cashier_id: ${this.$store.state.cashier.cashier_id}, date1:"${date1}", date2:"${date2}"){
                    choosen_numbers
                    cashier_id
                    others
                    winner_award
                    is_cancelled
                    payer_cashier_id

                   }
              }
              `,
          },
        });

        var moneyOnHand = moneyOnHandsResult.data.data.getMoneyOnHands;
        this.HorseTotalMoneyOnHand = 0;
        this.HorseTotalMoneyPaid = 0;
        this.HorseNoTickets = 0;
        this.HorseNoPaidTickets = 0;
        this.HorseNoCancelledTickets = 0;
        this.HorseNoBets = 0;

        //for (let i = 0; i < moneyOnHand.length; i++) {
        for (let t = 0; t < moneyOnHand.length; t++) {
          if (moneyOnHand[t].is_cancelled == 0) {
            var tickets = moneyOnHand[t].choosen_numbers.split(":");

            if (tickets[0] != "") {
              this.HorseNoBets = this.HorseNoBets + tickets.length;
              for (let j = 0; j < tickets.length; j++) {
                this.HorseTotalMoneyOnHand =
                  this.HorseTotalMoneyOnHand +
                  parseFloat(tickets[j].split("*")[3]);
              }
            }
          }

          if (
            moneyOnHand[t].winner_award != 0 &&
            moneyOnHand[t].cashier_id == moneyOnHand[t].payer_cashier_id
          ) {
            this.HorseTotalMoneyPaid =
              this.HorseTotalMoneyPaid + moneyOnHand[t].winner_award;

            this.HorseNoPaidTickets = this.HorseNoPaidTickets + 1;
          }

          if (moneyOnHand[t].is_cancelled == 1) {
            this.HorseNoCancelledTickets = this.HorseNoCancelledTickets + 1;
          } else this.HorseNoTickets = this.HorseNoTickets + 1;
        }

        this.HorseTotalMoneyPaid = this.HorseTotalMoneyPaid + paidFromCashier;

        this.HorseNoPaidTickets =
          this.HorseNoPaidTickets + paidFromCashierTicket;
      } catch (err) {}

      try {
        var paidMoneyFromYesterdayResult = await axios({
          method: "POST",
          url: this.$store.state.horseCashierURL,

          data: {
            query: `{
               getMoneyFromYesterday(cashier_id: ${this.$store.state.cashier.cashier_id}, date1:"${date1}", date2:"${date2}"){
                    paid_from_yesterday_id
                    cashier_id
                    user_id
                    winner_award
                    paid_date
                   }
              }
              `,
          },
        });

        var paidMoneyFromYesterday =
          paidMoneyFromYesterdayResult.data.data.getMoneyFromYesterday;
        this.paidHorseMoneyFromYesterday = 0;
        for (let k = 0; k < paidMoneyFromYesterday.length; k++) {
          this.paidHorseMoneyFromYesterday =
            this.paidHorseMoneyFromYesterday +
            paidMoneyFromYesterday[k].winner_award;
        }
      } catch (err) {}
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    async logout() {
      this.logoutLoading = true;
      try {
        var loggedinResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `mutation{
               loggedInCashier(
                cashier_id:${this.$store.state.cashier.cashier_id},
                is_loggedin:0
             )
                {
                      statusMessage

                   }
              }
              `,
          },
        });
        if (
          loggedinResult.data.data.loggedInCashier[0].statusMessage.toString() ==
          "true"
        ) {
          if (this.logoutDialog) {
            try {
              var cashierReportResult = await axios({
                method: "POST",
                url: this.$store.state.cashierURL,

                data: {
                  query: `mutation{
               addCashierReport(
                cashier_name:"${
                  this.$store.state.cashier.cashier_full_name
                }",              
                loggin_time: "${this.$store.state.cashier.loggin_time}",
          
                logout_time: "${Date.now()}",
                locationCoordinat: "${this.currentLocation}",
                ip_address: "${this.ipAddress}",
                total_tickets: ${this.noTickets + this.HorseNoTickets},
                paid_tickets: ${this.noPaidTickets + this.HorseNoPaidTickets},
                cancelled_tickets: ${
                  this.noCancelledTickets + this.HorseNoCancelledTickets
                },
                number_bets: ${this.noBets + this.HorseNoBets},
          
                total_money: ${
                  this.totalMoneyOnHand + this.HorseTotalMoneyOnHand
                },
                paid_money: ${
                  this.totalMoneyPaid +
                  this.HorseTotalMoneyPaid +
                  this.paidDogMoneyFromYesterday +
                  this.paidHorseMoneyFromYesterday
                },
                onhand_money: ${
                  this.totalMoneyOnHand -
                  this.totalMoneyPaid -
                  this.paidDogMoneyFromYesterday +
                  (this.HorseTotalMoneyOnHand -
                    this.HorseTotalMoneyPaid -
                    this.paidHorseMoneyFromYesterday)
                },
                   )
                {
                      statusMessage

                   }
              }
              `,
                },
              });
              if (
                cashierReportResult.data.data.addCashierReport[0].statusMessage.toString() ==
                "true"
              ) {
                var content = this.$refs.printReport;

                var newWindow = window.open("");
                this.logoutDialog = false;

                newWindow.document.write(content.outerHTML);

                newWindow.print();
                newWindow.close();

                this.$router.push({ name: "login" });
                this.$store.dispatch("loginCashier", "");

                this.$router.go();
              } else alert("Some thing went wrong, contact the admin");
            } catch (err) {
              alert(err);
            }
          }

          this.$router.push({ name: "login" });
          this.$store.dispatch("loginCashier", "");

          this.$router.go();
        } else alert("Some thing went wrong, contact the admin");
      } catch (err) {
        alert(err);
      }
      this.logoutLoading = false;
    },

    async getLocation() {
      const results = new Promise(function (resolve, reject) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve(position.coords.latitude + "," + position.coords.longitude);
          },
          (error) => {
            console.log(error.message);
          }
        );
      });
      return results;
    },

    async getIPAddress() {
      const results = new Promise(function (resolve, reject) {
        fetch("https://api.ipify.org?format=json")
          .then((x) => x.json())
          .then(({ ip }) => {
            resolve(ip);
          });
      });
      return results;
    },

    async logoutClicked() {
      this.currentLocation = await this.getLocation();
      this.ipAddress = await this.getIPAddress();
      this.logoutDialog = true;
    },

    //Horse this.HorseCurrentMousePosition= this.selectedHorseNumbers.length-1
  },

  async created() {
    if (this.$store.state.cashier != "") {
      this.getHelp();
      this.getCommonFeatures();
      this.getBalance();

      //this.getOdds();
      // this.HorseGetOdds();

      setInterval(async () => {
        await this.getCurrentGame();

        if (Number(this.HorseMinutes) == 5 && Number(this.HorseSeconds) <= 10)
          axios.get(
            this.$store.state.cashierURL.split("graphql")[0] + "getApp"
          );

        // if (
        //   (Number(this.HorseMinutes) <= 0 &&
        //     Number(this.HorseSeconds) <= 0 &&
        //     Number(this.minutes) <= 0 &&
        //     Number(this.seconds) <= 0) ||
        //   (Number(this.HorseMinutes) == 9 &&
        //     Number(this.HorseSeconds) == 0 &&
        //     Number(this.minutes) == 9 &&
        //     Number(this.seconds) == 0)
        // )
        //   axios.get(
        //     this.$store.state.cashierURL.split("graphql")[0] + "getApp"
        //   );

        if (
          this.partnerBalance.tempo_package != 0 &&
          this.partnerBalance.tempo_package <
            2 * this.partnerBalance.permanent_package
        ) {
          this.getMoneyOnHands();
          this.getBalance();
          this.getBlockedCashier();
          this.getLastTwoGames();
          // if (this.whichToShow == "keno") {

          if (this.currentGame.length == 0) {
            alert(
              "There is no game,Something went wrong, please refresh the page"
            );
          } else {
            if (this.currentGame[0].game_status == "pending") {
              if (this.timeInSeconds > 0) {
                this.isPlaying = false;
                this.timeInSeconds--;
                let hours = Math.floor(this.timeInSeconds / 3600);
                this.minutes = Math.floor(
                  (this.timeInSeconds - hours * 3600) / 60
                ); // get minutes
                this.seconds =
                  this.timeInSeconds - hours * 3600 - this.minutes * 60;

                if (this.minutes < 10) {
                  this.minutes = "0" + this.minutes;
                }
                if (this.seconds < 10) {
                  this.seconds = "0" + this.seconds;
                }

                this.timePercent = this.timePercent + 0.1852;
              } else {
                this.isPlaying = true;
              }
            } else {
              this.isPlaying = true;
            }
          }
        } else {
          this.getBalance();
        }
      }, 1000);
      setInterval(async () => {
        await this.getCurrentHorseGame();
        if (Number(this.minutes) == 5 && Number(this.seconds) <= 10)
          await axios.get(
            this.$store.state.horseCashierURL.split("graphql")[0] + "getApp"
          );
        if (
          this.partnerBalance.tempo_package != 0 &&
          this.partnerBalance.tempo_package <
            2 * this.partnerBalance.permanent_package
        ) {
          this.getHorseMoneyOnHands();
          // if (this.whichToShow == "Horse") {

          this.getLastTwoHorseGames();

          if (this.HorseCurrentGame.length == 0) {
            alert(
              "There is no game,Something went wrong, please refresh the page"
            );
          } else {
            if (this.HorseCurrentGame[0].game_status == "pending") {
              if (this.HorseTimeInSeconds > 0) {
                this.HorseIsPlaying = false;
                this.HorseTimeInSeconds--;
                let hours = Math.floor(this.HorseTimeInSeconds / 3600);
                this.HorseMinutes = Math.floor(
                  (this.HorseTimeInSeconds - hours * 3600) / 60
                ); // get minutes
                this.HorseSeconds =
                  this.HorseTimeInSeconds -
                  hours * 3600 -
                  this.HorseMinutes * 60;

                if (this.HorseMinutes < 10) {
                  this.HorseMinutes = "0" + this.HorseMinutes;
                }
                if (this.HorseSeconds < 10) {
                  this.HorseSeconds = "0" + this.HorseSeconds;
                }

                this.HorseTimePercent = this.HorseTimePercent + 0.1852;
              } else {
                this.HorseIsPlaying = true;
              }
            } else {
              this.HorseIsPlaying = true;
            }
          }
        }
      }, 1000);
    } else this.$router.push({ name: "login" });

    this.isLoad = false;
  },
};
</script>
<style scoped>
#navBar {
  background-color: rgba(27, 42, 61, 255);
}
#numbers,
#selectedNumbers {
  border-radius: 4px;
  background-color: rgba(41, 57, 73, 255);
  color: white;
}
#numbers:hover,
#dogbtn {
  background-color: rgba(41, 20, 30, 255);
}

#ticketListVertically {
  max-height: 360px;
  overflow-x: hidden;
  overflow-y: auto;
  transform: scaleX(-1);
}

#clearBtn:hover,
#applyToAll:hover {
  background-color: rgba(41, 20, 30, 255);
  color: white;
}
#clearBtn {
  border-radius: 4px;
  background-color: rgb(233, 68, 95);
  color: white;
}
#printBtn {
  background-color: rgba(41, 57, 73, 255);
}

#applyToAll {
  border-radius: 4px;
  background-color: white;
  color: grey;
}
.circle {
  width: 30px;
  height: 30px;

  border-radius: 50%;

  color: #fff;
  text-align: center;
  border: 1px solid white;
  float: center;
  margin-left: 20%;
  padding-top: 2px;
  font-size: 14px;
}

#selectedNum {
  border-radius: 4px;
}
</style>
