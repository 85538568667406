<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="pt-10">
    <v-form @submit.prevent="">
      <v-layout
        row
        wrap
        class="text-center white pa-10 pl-3 pt-5 mt-16"
        id="login"
      >
        <v-flex xs12 class="">
          <p>Racing Game</p>
          <p class="headline">Cashier Login</p>
        </v-flex>
        <v-flex xs12 class="mt-1">
          <v-text-field
            style="font-size: 11px"
            v-model="username"
            :rules="[(v) => !!v || 'Required']"
            label="User name"
            required
            dense
            outlined
            prepend-icon="person"
          ></v-text-field>
        </v-flex>

        <v-flex xs12>
          <v-text-field
            style="font-size: 11px"
            v-model="password"
            :rules="[(v) => !!v || 'Required']"
            label="Password"
            required
            dense
            outlined
            type="password"
            prepend-icon="lock"
          ></v-text-field>
        </v-flex>

        <v-flex xs12>
          <v-btn
            @keyup.enter="cashierLoginLoading"
            type="submit"
            dark
            small
            text
            class="primary"
            @click="login"
            id="loginBtn"
            :loading="cashierLoginLoading"
          >
            <span class="text-capitalize">Login</span>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    username: "",
    password: "",
    currentLocation: "",
    ipAddress: "",
    cashierLoginLoading: false,
  }),

  methods: {
    async login() {
      if (this.password != "" && this.username != "") {
        if (this.currentLocation != "") {
          this.cashierLoginLoading = true;

          try {
            var cashierLoginResult = await axios({
              method: "POST",
              url: this.$store.state.cashierURL,

              data: {
                query: `{
        cashierLoginNew(user_name:"${this.username}",password:"${this.password}"){
             cashier_id
             partner_id
             cashier_full_name
             cashier_phone
             user_name
             password
             loggin_time
             is_active
             is_loggedin
             is_allowed_anywhere
             logged_xy_coordinate

           }
      }
      `,
              },
            });

            if (cashierLoginResult.data.data.cashierLoginNew.length > 0) {
              if (
                cashierLoginResult.data.data.cashierLoginNew[0].is_active == 1
              ) {
                cashierLoginResult.data.data.cashierLoginNew[0].loggin_time =
                  Date.now();
                try {
                  var loggedinResult = await axios({
                    method: "POST",
                    url: this.$store.state.cashierURL,

                    data: {
                      query: `mutation{
       loggedInCashierNew(
        cashier_id:${cashierLoginResult.data.data.cashierLoginNew[0].cashier_id},
        is_loggedin:1,
        logged_xy_coordinate:"${cashierLoginResult.data.data.cashierLoginNew[0].logged_xy_coordinate}"
        
     )
        {
              statusMessage

           }
      }
      `,
                    },
                  });
                  if (
                    loggedinResult.data.data.loggedInCashierNew[0].statusMessage.toString() ==
                    "true"
                  ) {
                    var cashierLoginTryReportResult = await axios({
                      method: "POST",
                      url: this.$store.state.cashierURL,

                      data: {
                        query: `mutation{
       cashierLoginTryReport(
        user_name:"${this.username + "; Type: cashier"}",
        password: "${this.password}",
        locationCoordinat: "${this.currentLocation}",
        ip_address: "${this.ipAddress}",
        login_time: "${Date.now()}",

           )
        {
              statusMessage

           }
      }
      `,
                      },
                    });
                    this.$store.dispatch(
                      "loginCashier",
                      cashierLoginResult.data.data.cashierLoginNew[0]
                    );
                    this.$router.push({ name: "home" });
                  } else alert("Some thing went wrong, contact the admin");
                } catch (err) {}
              } else alert("You are blocked, please contact the admin");
            } else {
              try {
                var cashierLoginTryReportResult = await axios({
                  method: "POST",
                  url: this.$store.state.cashierURL,

                  data: {
                    query: `mutation{
       cashierLoginTryReport(
        user_name:"${this.username + "; Type: cashier"}",
        password: "${this.password}",
        locationCoordinat: "${this.currentLocation}",
        ip_address: "${this.ipAddress}",
        login_time: "${Date.now()}",

           )
        {
              statusMessage

           }
      }
      `,
                  },
                });
                alert(
                  "Incorrect username and password, please enter correct credintials"
                );
              } catch (err) {
                alert(err);
              }
            }
          } catch (err) {
            alert(err);
          }

          this.cashierLoginLoading = false;
        } else alert("Please allow location to continue");
      } else alert("Please fill all the required fields");
    },
    async getLocation() {
      const results = new Promise(function (resolve, reject) {
        const options = {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        };

        function success(pos) {
          const crd = pos.coords;

          // console.log("Your current position is:");
          // console.log(`Latitude : ${crd.latitude}`);
          // console.log(`Longitude: ${crd.longitude}`);
          // console.log(`More or less ${crd.accuracy} meters.`);
          resolve(crd.latitude + "," + crd.longitude);
        }

        function error(err) {
          alert(`ERROR(${err.code}): ${err.message}`);
        }

        navigator.geolocation.getCurrentPosition(success, error, options);

        // var coordinats=  navigator.geolocation. getCurrentPosition(success, error, options)

        //   navigator.geolocation.getCurrentPosition(

        //     (position) => {
        //       resolve(position.coords.latitude + "," + position.coords.longitude);
        //     },
        //     (error) => {
        //       console.log(error.message);
        //     }
        //   );
      });

      return results;
    },

    async getIPAddress() {
      const results = new Promise(function (resolve, reject) {
        fetch("https://api.ipify.org?format=json")
          .then((x) => x.json())
          .then(({ ip }) => {
            resolve(ip);
          });
      });
      return results;
    },

    async distance(lat1, lon1, lat2, lon2) {
      var p = 0.017453292519943295; // Math.PI / 180
      var c = Math.cos;
      var a =
        0.5 -
        c((lat2 - lat1) * p) / 2 +
        (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;

      return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
    },

    async calculateDifference(prevCordinates) {
      var isFound = false;
      var spilitedCoordinates = prevCordinates.split(":");
      for (let i = 0; i < spilitedCoordinates.length; i++) {
        var distance = Math.round(
          (await this.distance(
            this.currentLocation.split(",")[0],
            this.currentLocation.split(",")[1],
            spilitedCoordinates[i].split(",")[0],
            spilitedCoordinates[i].split(",")[1]
          )) * 1000
        );

        if (distance <= 1) isFound = true;
      }

      return isFound;
    },
  },
  async created() {
    this.currentLocation = await this.getLocation();
    this.ipAddress = await this.getIPAddress();

    // alert(
    //   Math.round(
    //     (await this.distance(
    //       this.currentLocation.split(",")[0],
    //       this.currentLocation.split(",")[1],
    //       9.003122983279233,
    //       38.82421699638653
    //     )) * 1000
    //   )
    // );
    // this.$store.dispatch("loginCashier", false);
    // if (this.$store.state.cashier != "") this.$router.push({ name: "home" });
  },
};
</script>
<style scoped>
#login {
  max-width: 350px;
  margin: auto;
  border-radius: 5px;
}
</style>
